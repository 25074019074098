<template>
  <modal-completar-venta
    v-if="showModal"
    :key="componentKeyModalCompletarVenta"
    :geocoder="geocoder"
    :token-auth="tokenAuth"
    :idVenta="idVenta"
  />
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> Listado de
            últimos referidos
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th data-priority="3">Fecha/Hora</th>
            <th data-priority="1">Nombres y apellidos</th>
            <th>Celular</th>
            <th>Email</th>
            <th>Estado</th>
            <!-- <th data-priority="2">Acción</th> -->
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";
import ModalCompletarVenta from "./ModalCompletarVenta.vue";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
    ModalCompletarVenta,
  },
  props: ["userId", "tokenAuth"],
  data() {
    return {
      loading: true,
      transactions: [],
      geocoder: null,
      componentKeyModalCompletarVenta: 100,
      idVenta: "",
      showModal: false,
      columns: [
        { data: "dateFormated" },
        {
          data: null,
          render: function (data, type, row, meta) {
            return `${data.displayName}  ${
              data.notas?.length > 0
                ? "<br> <b>Nota: </b>" + data.notas[data.notas.length - 1].nota
                : ""
            }`;
          },
        },
        { data: "phone" },
        { data: "email" },
        { data: "estado" },
        // {
        //   data: null,
        //   render: function (data, type, row, meta) {
        //     if (
        //       data.estado === "INSTALADO: Contrato instalado" ||
        //       data.estado === "CONTRATO INSTALADO" ||
        //       data.estado === "LLAMADA DE PRUEBA" ||
        //       data.estado === "CANCELA SOLICITUD" ||
        //       data.estado === "NO DESEA" ||
        //       data.estado === "SIN COBERTURA / NO APLICA" ||
        //       data.estado === "CONTRATA POR OTRO CANAL" ||
        //       data.estado === "CONTRATA A NOMBRE DE OTRA PERSONA"
        //     ) {
        //       return data.estado;
        //     } else {
        //       if (data.estado === "Incompleto") {
        //         return `<button data-id="${data.id}"   class="completar btn btn-primary btn-sm" type="button" style="width:100%;">  Completar  </button>`;
        //       } else {
        //         return `<button data-id="${data.id}"   class="consultar btn btn-primary btn-sm" type="button" style="width:100%;">  Revisar  </button>`;
        //       }
        //     }
        //   },
        // },
      ],
      options: {
        responsive: true,
        ordering: false,
        lengthMenu: [
          [5, 15, 25, 35, 50, -1],
          [5, 15, 25, 35, 50, "All"],
        ],
        pageLength: 5,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.cargarScript();

    this.$eventBus.on("reiniciarModalCompletarVenta", () => {
      this.componentKeyModalCompletarVenta += 1;
      this.showModal = false;
    });

    $(document).off("click", ".consultar");

    $(document).on("click", ".consultar", (evt) => {
      const data = $(evt.target).data("id");

      this.consultarStatus(data);
    });

    $(document).off("click", ".completar");

    $(document).on("click", ".completar", (evt) => {
      const data = $(evt.target).data("id");

      this.checkVenta(data);
    });

    this.getListado();
  },
  methods: {
    instanceMap() {
      this.geocoder = new google.maps.Geocoder();
    },

    cargarScript() {
      // As an instance method inside a component
      this.$loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyD_vYcja0tqGvy3RgrDwD2bqLUxcQNc3v0"
      )
        .then(() => {
          // Script is loaded, do something
          this.instanceMap();
        })
        .catch(() => {
          // Failed to fetch script
          this.cargarScript();
        });
    },

    checkVenta(id) {
      this.$swal({
        html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Comprobando venta</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: "top",
      });

      this.$https
        .post("/ventas/checkVenta/", { tokenAuth: this.tokenAuth, id: id })
        .then((response) => {
          this.$swal.close();

          if (response.data.code == 200) {
            this.idVenta = id;

            this.showModal = true;
          } else {
            this.refresh();
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code === 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("2) Ocurrió un error inesperado: " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    consultarStatus(id) {
      this.$swal({
        html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: "top",
      });

      this.$https
        .post("/ventas/consultarVenta/", {
          tokenAuth: this.tokenAuth,
          id: id,
        })
        .then((response) => {
          this.$swal.close();

          if (response.data.code == 200) {
            this.refresh();
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });
          } else {
            this.refresh();
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code === 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("2) Ocurrió un error inesperado: " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    refresh() {
      $(document).off("click", ".consultar");

      this.$eventBus.emit("reiniciarListadoVentas");
    },

    getListado() {
      const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId);

      this.$https
        .post("/ventas/getVentas/", {
          tokenAuth: this.tokenAuth,
          userId: userIdEncripted,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.loading = false;

            this.transactions = response.data.transactions;
          } else {
            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            } else {
              if (response.data.code == 404) {
                this.loading = false;
              } else {
                this.getListado();
              }
            }
          }
        })
        .catch((error) => {
          this.getListado();
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
</style>
