<template>

       <div class="card" style="margin-bottom:10px">

              <div class=" card-body">

                <div class="row">

                  <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" style="margin-bottom:10px;  justify-content: space-between;display: flex;">
                  
                      <h5 class="card-title mb-0 text-uppercase font-weight-bold" style="font-size: 12px;">Saldo disponible</h5>
                      <div  v-if="!loading" @click="refresh"  style="cursor:pointer;position: relative; top:-5px"> 
                        <i  style="font-size: 6px" class="fas fa-sync text-sm opacity-10"></i>
                      </div>
                      
                  </div>

                  <div class="col-xl-7 col-lg-7 col-md-7 col-7 col-sm-7">
                    <Skeletor v-if="loading" :shimmer="true" height="38" width="100%" style="margin-bottom:0px"/>
                    <p v-else class="font-weight-bolder priceCard"><span style="font-size:14px">$</span>{{ balance }}</p>
                  </div>

                  <div class="col-xl-5 col-lg-5 col-md-5 col-5 col-sm-5" style="display:flex">
                    <button @click="retirar()"  class="btn btn-primary btn-sm col-10" type="button" style="width:100%;padding: 5px;margin:auto">Retirar</button>
                  </div>



                </div>
              </div>

            </div>
 
</template>

<script>

/* eslint-disable */
export default {
components: {
    



},
props: ['userId', 'tokenAuth'],
data() {


    return {
        loading: true,
        balance:0

    }
},
computed: {

},
watch: {

},
mounted() {

    this.getSaldo();

},
methods: {

    retirar(){

   
                           this.$eventBus.emit('showModalRetiro');                                      

    },

    refresh() {

            this.$eventBus.emit('reiniciarSaldos')
          },

          getSaldo(){

                const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId)
             
                this.$https.post('/profile/getBalance/', { tokenAuth: this.tokenAuth, userId: userIdEncripted }).then(response => {



                if (response.data.code == 200) {
                this.loading = false;


  
                this.balance= response.data.balance;
                

                } else {




                if (response.data.code == 401) {

             
                
                        localStorage.removeItem('userData')
this.$store.state.user="";


                     // Redirect to login page
                     this.$router.push({ name: 'login' })
                } else {
                        this.getSaldo();
                }
                }
                }).catch(error => {
                          this.getSaldo();
                })
                },



    

},
}
</script>

<style lang="scss">



</style>
      