
<template>

    <div>
        <modal-photo v-if="showModalPhoto" :key="componentKeyModalPhoto" :user-id="userId" :token-auth="tokenAuth" :image="image"  />
  <div class="row">

            <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3">

                </div> 
            <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                <div class="card">

                    <div class="card-body">

                        <div class="row" style=" margin-bottom: 15px;"> 




                            <div style="position: relative;" class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12" align="center">
                

                            <v-lazy-image :src="photo" class="rounded-circle img_profile" style="border: 4px solid white;width: 100px"/>

                            <input type="file"  id="upload" ref="profilePhoto" accept="image/jpeg, image/jpg"  @change="loadImage($event)" hidden/>
                            <label for="upload" class="camera_photo"> <i style="margin: auto;" class="fas fa-camera"></i></label>

                         

                        
                            </div>

                            <div  class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12" align="center">
                
                                <p style="margin-bottom:0px; margin-top:15px;    line-height: 18px; text-align: center;font-size:15px; font-weight: bold;">{{ displayName }}</p>
                            <p style="margin-bottom:15px; text-align: center;font-size:13px">{{ dni }}</p>

                            <form  role="form" autocomplete="off" @submit.prevent="onSubmit">

                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-bottom:10px;    text-align: left;">
                                        <label  for="example-text-input" class="form-control-label"  style="font-weight: bold; font-size: 12px; text-align: left;">Email (*):</label>
                                        <Skeletor v-if="loading" :shimmer="true" height="40" width="100%"  />
                                        <input v-else-if="permitirEmail" :disabled="isDisabled"  placeholder="Ingrese su email"  class="form-control col-lg-12" required type="email"  autocomplete="off" v-model="email" />   
                                            <p style="margin:0px; text-align: left;" v-else>{{ email }}</p>

                                        </div>    
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-bottom:10px;    text-align: left;">
                                        <label for="example-text-input" class="form-control-label">Sexo (*):</label>
                                        <Skeletor v-if="loading" :shimmer="true" height="40" width="100%"  />
                                        <select v-else @change="onChange2($event)" :disabled="isDisabled"  required class="form-select form-select-md" >
                                        <option  selected value="">Seleccione una opción</option>

                                            <option   :selected="opcion.id == genero ? true : false"  v-for="opcion in optionsGeneros" :key="opcion.id" :value="opcion.value">{{ opcion.value }}</option>

                                            </select>



                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-bottom:10px;    text-align: left;">
                                        <label for="example-text-input" class="form-control-label">Estado civil (*):</label>
                                        <Skeletor v-if="loading" :shimmer="true" height="40" width="100%"  />
                                        <select v-else @change="onChange1($event)" :disabled="isDisabled"  required class="form-select form-select-md" >
                                        <option  selected value="">Seleccione una opción</option>

                                            <option   :selected="opcion.id == estadoCivil ? true : false"  v-for="opcion in optionsEstadoCivil" :key="opcion.id" :value="opcion.value">{{ opcion.value }}</option>

                                            </select>



                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-bottom:10px;    text-align: left;">
                                        <label  for="example-text-input" class="form-control-label"  style="font-weight: bold; font-size: 12px; text-align: left;">Ciudad (*):</label>
                                        <Skeletor v-if="loading" :shimmer="true" height="40" width="100%"  />
                                        <input v-else :disabled="isDisabled"  placeholder="Ingrese su ciudad"  class="form-control col-lg-12" required type="text"  autocomplete="off" v-model="ciudad" />   


                                        </div>   

                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">

                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <Skeletor v-if="loading" :shimmer="true" height="40" width="100%"  />
                                            <button id="btn_guardar" v-else type="submit" class="btn btn-primary" style="width: 100%;margin-top:15px" :disabled="isDisabled"> Actualizar</button>

                                        </div>

                              


                                </div>



                                </form>


                                </div>



                            </div>
                        
                    </div>
    
                  
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3">

            </div> 

            </div>
  </div>



</template>

<script>
/*eslint-disable */
import ModalPhoto from "./ModalPhoto.vue";
export default {
    name: "EdicionPerfil",
    components: {
        ModalPhoto
    },
    data() {

        let datos = JSON.parse(this.$store.state.user);
        let optionsGeneros= [
                {"id":"Masculino","value":"Masculino"},
                {"id":"Femenino","value":"Femenino"},
                {"id":"Transgenero","value":"Transgénero"},
                ]


                let optionsEstadoCivil= [
                {"id":"Soltero/a","value":"Soltero/a"},
                {"id":"Divorciado/a","value":"Divorciado/a"},
                {"id":"Casado/a","value":"Casado/a"},
                {"id":"Viuda/o","value":"Viuda/o"},
                ]

      let photo="";

      if(datos.photoUser === ""){
                photo="/img/user.svg"
            }else{
                photo=datos.photoUser;
            }

        return {
            userId:datos.userId,
            tokenAuth:datos.tokenAuth,
            permitirEmail:false,
            dni:datos.dni,
            displayName:datos.displayName,
            photo:photo,
            ciudad:"",
            showModalPhoto:false,
            optionsGeneros:optionsGeneros,
            optionsEstadoCivil:optionsEstadoCivil,
            genero:"",
            loading:true,
            estadoCivil:"",
            isDisabled:false,
            email:"",
            componentKeyModalPhoto:100,
            type:"",
          image: {
                        src: null,
                        type: null
                    }
        }
    },
    activated() {
      
        if (this.isMobile){
       
       this.$eventBus.emit('inactiveShowMenu')
     }


    
    
},
    methods: {

        getProfile(){
          this.loading=true;
         
  
        this.$https.post('/profile/getProfile/', {tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
            
       
          if (response.data.code == 200) {

            this.email=response.data.email;
            this.ciudad=response.data.ciudad;
            this.estadoCivil=response.data.estadoCivil;
            this.genero=response.data.sexo;
            this.permitirEmail=response.data.permitirEmail;
 
              this.loading=false;
       
          } else {
    

              if (response.data.code == 401) {
                
                               
                localStorage.removeItem('userData')
                    this.$store.state.user="";


                // Redirect to login page
                this.$router.push({ name: 'login' })
    
              }else{ 
                this.getProfile();
    
              }
              
            
          }
        }).catch(error => {
             this.getProfile();
        })
        },

        onChange2(event){

       

this.genero=event.target.value;


},



onChange1(event){

       

this.estadoCivil=event.target.value;


},

        onSubmit(event) {
            event.preventDefault();

            this.isDisabled=true;
            const data_json = { userId: this.userId, genero:this.genero, email: this.email, ciudad:this.ciudad, estadoCivil: this.estadoCivil }
                 

            document.getElementById('btn_guardar').innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> Actualizando'

                this.$https.post('/profile/updateProfile2/', { tokenAuth: this.tokenAuth, data: data_json }).then(response => {
                    
                    document.getElementById('btn_guardar').innerHTML = 'Actualizar'
                    this.isDisabled=false;
                    if (response.data.code == 200) {

                      

                    this.$toast.success(response.data.message, {
                    position: "top-right",
                    max: 10
                    })

                   
                    } else {


                        this.$toast.error(response.data.messag, {
                    position: "top-right",
                    max: 10
                    })
                
                    if (response.data.code == 401) {
                                        
                            localStorage.removeItem('userData')
                                this.$store.state.user="";


                            // Redirect to login page
                            this.$router.push({ name: 'login' })
                    }
                    }
                }).catch(error => {

                    this.$toast.error(error, {
                    position: "top-right",
                    max: 10
                    })

                })

        },

        loadImage(event) {


       

            if(event.target.files[0].type=== "image/jpeg"){

                this.type="image/jpeg"

                const FileSizeBanner = event.target.files[0].size / 1024 / 1024
            // let foto=event.target.files[0];



            if (FileSizeBanner > 5) {
                this.$refs.profilePhoto.value = null;

            this.$toast.error("La imagen debe pesar menos de 5mb", {
                                        position:"bottom-right",
                                        max: 10
                                        })
            }else{

                    // Reference to the DOM input element
            const { files } = event.target;
            // Ensure that you have a file before attempting to read it
            if (files && files[0]) {
            // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
            if (this.image.src) {
            URL.revokeObjectURL(this.image.src)
            }
            // 2. Create the blob link to the file to optimize performance:
            const blob = URL.createObjectURL(files[0]);

            // 3. The steps below are designated to determine a file mime type to use it during the 
            // getting of a cropped image from the canvas. You can replace it them by the following string, 
            // but the type will be derived from the extension and it can lead to an incorrect result:
            //
            // this.image = {
            //    src: blob;
            //    type: files[0].type
            // }

            // Create a new FileReader to read this image binary data
            const reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
            // Note: arrow function used here, so that "this.image" refers to the image of Vue component
            this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: this.type,
            };
            };
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsArrayBuffer(files[0]);
            
                this.showModalPhoto=true;
            }

            

            }


            }else{

                this.$refs.profilePhoto.value = null;

                this.$toast.error("El archivo debe tener una extensión JPG/JPEG", {
                                        position:"bottom-right",
                                        max: 10
                                        })

            }


            },


    },
    mounted() {

        this.getProfile();

        this.$eventBus.on('reiniciarModalPhoto', () => {
            this.$refs.profilePhoto.value = null;
            
        this.componentKeyModalPhoto+=1;
      this.showModalPhoto = false
    });

    this.$eventBus.on('updateFotoPerfil', (url) => {
      this.photo = url;
    
    })

}
}    
</script>


<style scoped>

</style>