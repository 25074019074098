<template>
  <div>
    <div class="row">
      <router-link
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        :to="{ name: 'Perfil' }"
      >
        <div class="card2">
          <div class="card-body">
            <div class="row">
              <div
                class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                align="center"
              >
                <v-lazy-image
                  :src="photo"
                  class="rounded-circle"
                  style="background: white; width: 40px"
                />
              </div>

              <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <p
                  style="
                    margin: 0px;
                    font-size: 12px;
                    text-transform: capitalize;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  {{ displayName }}
                </p>
                <p style="text-align: left; font-size: 11px; margin: 0px">
                  Editar mi perfil
                </p>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-top: 10px; margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body">
            <div class="d-flex">
              <h6
                class="mb-0"
                style="font-size: 14px"
                :class="this.$store.state.isRTL ? 'ms-2' : ''"
              >
                Modo {{ modo }} activado
              </h6>
              <div class="form-check form-switch ps-0 ms-auto my-auto">
                <input
                  class="form-check-input mt-1 ms-auto"
                  type="checkbox"
                  :checked="this.$store.state.darkMode"
                  @click="setDarkMode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link
        :to="{ name: 'descripcion' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i style="font-size: 16px" class="fas fa-list text-success"></i
              ><br />Descripcion XTRIM
            </p>
          </div>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'recursos' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i
                style="font-size: 16px"
                class="fa fa-book-open text-success"
              ></i
              ><br />Recursos
            </p>
          </div>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'ventas' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i
                style="font-size: 16px"
                class="fa fa-shopping-cart text-success"
              ></i
              ><br />Mis Referidos
            </p>
          </div>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'estadisticas' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i style="font-size: 16px" class="fas fa-eye text-success"></i
              ><br />Visión 360
            </p>
          </div>
        </div>
      </router-link>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 1rem;
          text-align: center;
        "
      >
        <button
          @click="exit()"
          class="btn btn-primary btn-sm"
          type="button"
          style="width: 100%"
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import myMixin from "../mixin.js";

import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
export default {
  name: "Mas",
  data() {
    const { logout } = myMixin();

    let datos = JSON.parse(this.$store.state.user);

    let photo = "";

    if (datos.photoUser === "") {
      photo = "/img/user.svg";
    } else {
      photo = datos.photoUser;
    }

    return {
      userId: datos.userId,
      isMobile: this.$isMobile,
      displayName: datos.displayName,
      tokenAuth: datos.tokenAuth,
      logout: logout,
      modo: "",
      photo: photo,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {
    setDarkMode() {
      if (this.$store.state.darkMode) {
        localStorage.setItem("skin", "light");
        this.$store.state.darkMode = false;
        this.modo = "día";
        deactivateDarkMode();
        return;
      } else {
        localStorage.setItem("skin", "dark");
        this.$store.state.darkMode = true;
        this.modo = "noche";
        activateDarkMode();
      }
    },
    exit() {
      this.$eventBus.emit("clearOverlaySidebar", true);

      this.logout(this.userId, this.tokenAuth, true);
    },
  },
  mounted() {
    if (this.$store.state.darkMode) {
      this.modo = "noche";
    } else {
      this.modo = "día";
    }
  },
};
</script>

<style scoped></style>
