<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE TRANSACCIONES</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <div v-else @click="refresh">
                        <i  style="cursor:pointer; margin:auto"
                        class="fas fa-sync text-sm opacity-10"></i>
                            </div>
                  
                </div>

            </div>

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    





                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                width="100%">
                <thead>
                    <tr>
                        <th>Fecha/Hora</th>
                        <th data-priority="1">Monto</th>
                        <th>Estado</th>
                        <th>Tipo</th>
                        
                    </tr>
                </thead>





            </DataTable>


        </div>

    </div>
</template>
<script>
/* eslint-disable */

import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';

DataTable.use(DataTablesCore);

export default {
    components: {

        DataTable


    },

    data() {

        return {
            loading: true,
            isMobile: this.$isMobile,
            transactions: [],
            columns: [
                { data: 'dateFormated' },
                { data: 'monto' },
                { data: 'status' },
                { data: 'source' },
            ], options: {
                responsive: true,
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 5,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            },

        }
    },
    activated() {
      
      if (this.isMobile){
        this.$eventBus.emit('activeShowMenu')
      }


    
    
},

    methods: {
        cargaData(){
            //console.log('Hoooooooola');
            let user = JSON.parse(localStorage.getItem('userData'))
            const userIdEncripted = this.$encryptBackoffice.encrypt(user.userId)        

            this.$https.post('/banks/transacciones/', { tokenAuth: user.tokenAuth, userId: userIdEncripted }).then(response => {
                //console.log(response);

                if (response.data.code == 200) {
                    this.transactions = response.data.Transacciones;
                    this.loading = false
                }else{

                   

                            if(response.data.code == 401){
                            this.$toast.error(response.data.message, {
                                position:"top-right",
                                max: 10
                            })
                        }else{

                            if(response.data.code === 204){

                                this.loading = false

                            }else{
                                this.cargaData();
                            }
                           
                        }


                }

         


            }).catch(error => {
                this.cargaData();
            })


        },

        refresh(){
            this.loading = true
            this.cargaData()

        }
    },
    mounted(){
        this.cargaData()
    }
}

</script>

<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';


</style>