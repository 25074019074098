<template>
      <div class="card" :class="{'card2': isMobile, 'margin_card': !isMobile}" style="background: #5e005f;" >

              <div class=" card-body">

          <div class="row">
        
            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12">

              <h6 style="font-size:14px;  color: white; font-weight: bold;margin-bottom: 20px; line-height: 15px;text-align:center;">¡Refiere el Internet de Xtrim y gana $20 por cada Cliente nuevo!</h6>

              </div>

       

            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12">

      
                    <div class="input-group justify-content-center">
                      <input style="background-color: #e9ecef;    color: black;" type="text" required :readonly="true" v-model="link3"
                        class="form-control col-lg-6 text-center" id="link3" ref="link3" aria-describedby="button-addon2">
                      <button class="btn-outline-secondary btn2" style="    background: #e9ecef;color: #000;" @click="copyInputValue('link3')" >
                        <i class="fa fa-clone" aria-hidden="true" style="margin-right: 5px;"></i>
                      </button>
                    </div>

                    <hr class="horizontal dark" style="margin-bottom: 10px;" />

              <a href="https://www.youtube.com/watch?v=bkVRdA8Dq-Y&ab_channel=XtrimPro" target="_blank"><p class="a_link2" style="color:white">Ver tutorial de uso</p></a>



            </div>

           




                </div>
              </div>



            </div>
    
        
        </template>
        
        <script>
  
        export default {
          components: {
           
    
    
          },
          props: ['promoCode', 'isMobile'],
          data() {
        
        
            return {
        
                link3: '',
            }
          },
          computed: {
        
          },
          watch: {
        
          },
          mounted() {
  
            this.link3 = window.location.host + '/venta/' + (this.promoCode);

          },
          methods: {
  
              copyInputValue(model) {
  
  
              const inputElement = document.getElementById(model);
              inputElement.select();
              document.execCommand('copy');
              inputElement.setSelectionRange(0, 0);
              this.$toast.success("Link Copiado exitosamente", {
              position: "top-right",
              max: 10
              })
  
  
              }
  
           
        
          },
        }
        </script>
        
        <style scope>

      
      </style>
        