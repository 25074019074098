
<template>
 
    <div>


        <div class="row">


            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="card">
       

                    <div class="card-body">

                        <p style="    font-size: 16px;font-weight: bold;text-align: center;">Descripción general</p>

                        <p style="font-size:14px;text-align: justify;">
                        

                            Xtrim y PlanPro se unen para ofrecerles la mas moderna plataforma digital de Emprendimiento XTRIMPRO , donde  todos pueden referir clientes nuevos y ganar dinero por cada persona que contrate el Internet mas rápido y al mejor precio del Ecuador.  
                       
Registrense gratuitamente y empiecen a ganar con XtrimPro (registro gratis por tiempo limitado) </p>
<p style="font-size:14px;text-align: justify;">

Ademas de personas, los usuarios pueden referir Empresas, pero la comisión se pagara solo si se contrata uno de los 4 planes.  Si la Empresa necesita un plan personalizado, esto lo venderá directamente Xtrim y no habra comisiones en este caso.</p>
<p style="font-size:14px;text-align: justify;">

Los pagos de las comisiones seran realizados por la empresa PlanProcad S.A.  Estas comisiones seran pagadas únicamente por transferencia bancaria a la cuenta que registren en su oficina virtual.  Ademas esta cuenta debe ser de la persona registrada en la plataforma.</p>
<p style="font-size:14px;text-align: justify;">

Si desean pueden  registrar en la plataforma a su familia, invitándolos con el link de registro para que todos puedan gozar de este beneficio.</p>
<p style="font-size:14px;text-align: justify;">

<b>Comisiones en 3 Niveles: </b><br>
<ul>
            <li style="font-size:14px;"><b>Con Factura:</b> <ul>
                <li> Venta Directa recibe $20.00 USD</li>
                <li> Venta Nivel 1 recibe $3.00 USD</li>
                <li> Venta Nivel 2 recibe $2.00 USD</li>
            </ul> 
            </li>

   
          </ul>




</p>
<p style="font-size:14px;text-align: justify;">
Una vez ingreses a tu referido el Contact Center de Xtrim se comunicara con ellos dentro de las siguientes 24 horas.</p>
<p style="font-size:14px;text-align: justify;">

Las comisiones se pagaran una vez Xtrim este instalado en el domicilio de tu referido.</p>
<p style="font-size:14px;text-align: justify;">

Esta plataforma tiene certificaciones de seguridad bancaria, por lo que tus datos se encuentran protegidos.</p>


                       <p style="    font-size: 16px;font-weight: bold;text-align: center;">Términos y condiciones</p> 

                       <p style="font-size:14px;text-align: justify;">
          En mi calidad de usuario (en adelante “el usuario” o “usuario”) de esta plataforma (en adelante plataforma “PLANPRO”) de titularidad de PLANPROCAD S.A., reconozco expresamente que al utilizarla debo sujetarme en todo momento a lo aquí establecido. Al acceder o utilizar esta plataforma web, <b>acepto expresa, libre y voluntariamente los siguientes Términos y Condiciones de Uso.</b>  
        </p>
        <p style="font-size:14px;text-align: justify;">
          Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular, se reserva el derecho de negarme el acceso a la misma en cualquier caso que considere apropiado, en particular si yo como usuario: 
        </p>

        <p style="font-size:14px;text-align: justify;">
          <ol>
            <li style="font-size:14px;">Proporciono <b>datos falsos</b></li>
            <li style="font-size:14px;"><b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier forma; y,</li>
            <li style="font-size:14px;">Incumplo <b>cualquier normativa legal</b> aplicable respecto del acceso o el uso de la presente plataforma. </li>
      
          </ol>
        </p>

        <p style="font-size:14px;text-align: justify;">
          Acepta libre, voluntaria y expresamente que soy el único responsable del contenido que cargue, publique, envíe por correo electrónico, transmita o de cualquier forma ponga a disposición a través de esta plataforma.
        </p>

        <p style="font-size:14px;text-align: justify;">
     
          Adicionalmente me obligo expresa, libre y voluntariamente a <b>no utilizar la presente plataforma en forma alguna que sirva directa o indirectamente para:</b>
           </p>

           <p style="font-size:14px;text-align: justify;">
          <ul>
            <li style="font-size:14px;">Dañar a otras personas o animales de cualquier forma; </li>
            <li style="font-size:14px;">Realizar declaraciones falsas; </li>
            <li style="font-size:14px;">Difundir de cualquier forma contenido que viole un derecho de propiedad intelectual de terceros, incluyendo pero no limitado a marcas, derechos de autor, secretos empresariales, patentes y diseños industriales; y, </li>
            <li style="font-size:14px;">Violar cualquier ley o norma jurídica nacional o internacional. </li>
          </ul>
        </p>

        <p style="font-size:14px;text-align: justify;">
     
          Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular se reserva el derecho de modificar a su discreción los presentes términos y condiciones, comunicándome el particular, siempre de acuerdo con la ley ecuatoriana.
  
        </p>

        <p style="font-size:14px;text-align: justify;">
     
          <b>Datos Personales:</b> Autorizo expresamente a PLANPROCAD S.A. a utilizar mis datos personales entregados o generados por medio de mi utilización de la plataforma PLANPRO. Esta autorización incluye los siguientes usos:
         </p>

         <p style="font-size:14px;text-align: justify;">
          <ul>
            <li style="font-size:14px;">Acceso a datos; </li>
            <li style="font-size:14px;">Consulta de buró crediticio; </li>
        </ul>
        </p>
                       
       

                    </div>
                </div>
            </div>



            </div>


</div>
</template>

<script>
/*eslint-disable */


export default {
    name: "Descripcion",
    data() {

    

        return {
            isMobile: this.$isMobile,
        }
    },
    methods: {
     

    },
    activated() {
    
          if (this.isMobile){
       
            this.$eventBus.emit('inactiveShowMenu')
          }


        
        
    },
    mounted() {


}
}    
</script>


<style scoped>


</style>