<template>
    <VueFinalModal v-model="showModal" :esc-to-close="true"  class="modal-container" content-class="modal-content">
 

      <div class="modal__content">

        <div class="row"> 

          
          <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11">  

          </div>

          <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 " @click="closed2">  


          <i class="fas fa-times-square close_modal"></i>

          </div>


          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
            <p style="text-align: center; font-weight: bold; font-size:13px; margin-bottom:10px"> Ingrese el código enviado a su celular</p>
         
            <pin-input
              ref="code"
                class="wrapper"
                v-model="code"
                :secure="false"
                :characterPreview="true"
                :charPreviewDuration="500"
                :length="6"
                input-class="pinInput"
                @completed="handleOnComplete($event)"
              />

          </div>


          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 

            <hr class="horizontal dark" />

            <p style="text-align: center; font-weight: bold; font-size:13px; margin:0px">¿Ya eres usuario y no recibiste el código SMS?</p>

            <div class="text-center" >
            <argon-button class="mt-1"   @click="contrasena()" variant="gradient" color="success"  style="width: 100%;padding: 10px;" size="lg"> Inicia sesión con contraseña</argon-button>
            </div>
      
    

         


            </div>

        </div>

     

 
 

      </div>
    </VueFinalModal>


    <VueFinalModal v-model="showModalContraseña" :esc-to-close="true" @closed="closed" class="modal-container" content-class="modal-content">

 <div class="modal__content">

   <div class="row"> 

    <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11">  

      </div>

      <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 " @click="closed">  


      <i class="fas fa-times-square close_modal"></i>

      </div>


     <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" > 


       <form  autocomplete="off" @submit.prevent="onSubmitPassword">

         <div class="row"> 

             <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
              <p style="text-align: left; font-weight: bold; font-size:13px; margin-bottom:5px">Ingrese su número celular</p>

              <MazPhoneNumberInput :disabled="isDisabled" type="tel" default-country-code="EC" style="width: 100%;" no-flags no-example required show-code-on-list
            size="md" v-model="phoneNumberPassword"  @update="resultsPassword = $event" :translations="{
            countrySelector: {
                placeholder: 'Código',
                error: 'Elija un pais',
            },
            phoneInput: {
                placeholder: 'Nro. celular',
                example: 'Ejemplo:',
            },
            }" />

               <div class="input-group mb-3" style="margin-top:15px">
                 <input  required 
                 style="border: 1px solid #d2d6da !important;"
                 :readonly="isDisabled"
                     class="form-control" 
                     :type="passwordFieldTypeNew"
                     placeholder="Ingrese su contraseña"
                       v-model="password"
                     aria-describedby="button-addon2">
                 <button type="button" class="btn-outline-secondary btn2"  @click="togglePasswordNew">

                     <i style="font-size:14px" :class="iconEye"></i>


                   
                 </button>

             </div>

             <button  id="btn_loginPassword" ref="btn_loginPassword" type="submit" class="btn btn-primary btn-sm" style="width: 100%;" :disabled="isDisabled"> Iniciar sesión </button>



             </div>

         </div>


       </form>

       
    


    


       </div>

   </div>






 </div>
</VueFinalModal>


    <VueFinalModal v-model="showTerminos" :esc-to-close="false" :click-to-close="false" :prevent-click="false" class="modal-container" content-class="modal-content">
      <span class="modal__title">Términos y condiciones de uso</span>
      <div class="modal__content">
       
        <p style="font-size:12px;text-align: justify;">
          En mi calidad de usuario (en adelante “el usuario” o “usuario”) de esta plataforma (en adelante plataforma “PLANPRO”) de titularidad de PLANPROCAD S.A., reconozco expresamente que al utilizarla debo sujetarme en todo momento a lo aquí establecido. Al acceder o utilizar esta plataforma web, <b>acepto expresa, libre y voluntariamente los siguientes Términos y Condiciones de Uso.</b>  
        </p>
        <p style="font-size:12px;text-align: justify;">
          Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular, se reserva el derecho de negarme el acceso a la misma en cualquier caso que considere apropiado, en particular si yo como usuario: 
        </p>

        <p style="font-size:12px;text-align: justify;">
          <ol>
            <li>Proporciono <b>datos falsos</b></li>
            <li><b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier forma; y,</li>
            <li>Incumplo <b>cualquier normativa legal</b> aplicable respecto del acceso o el uso de la presente plataforma. </li>
      
          </ol>
        </p>

        <p style="font-size:12px;text-align: justify;">
          Acepta libre, voluntaria y expresamente que soy el único responsable del contenido que cargue, publique, envíe por correo electrónico, transmita o de cualquier forma ponga a disposición a través de esta plataforma.
        </p>

        <p style="font-size:12px;text-align: justify;">
     
          Adicionalmente me obligo expresa, libre y voluntariamente a <b>no utilizar la presente plataforma en forma alguna que sirva directa o indirectamente para:</b>
           </p>

           <p style="font-size:12px;text-align: justify;">
          <ul>
            <li>Dañar a otras personas o animales de cualquier forma; </li>
            <li>Realizar declaraciones falsas; </li>
            <li>Difundir de cualquier forma contenido que viole un derecho de propiedad intelectual de terceros, incluyendo pero no limitado a marcas, derechos de autor, secretos empresariales, patentes y diseños industriales; y, </li>
            <li>Violar cualquier ley o norma jurídica nacional o internacional. </li>
          </ul>
        </p>

        <p style="font-size:12px;text-align: justify;">
     
          Reconozco y acepto que la plataforma PLANPRO en la persona de su administrador o titular se reserva el derecho de modificar a su discreción los presentes términos y condiciones, comunicándome el particular, siempre de acuerdo con la ley ecuatoriana.
  
        </p>

        <p style="font-size:12px;text-align: justify;">
     
          <b>Datos Personales:</b> Autorizo expresamente a PLANPROCAD S.A. a utilizar mis datos personales entregados o generados por medio de mi utilización de la plataforma PLANPRO. Esta autorización incluye los siguientes usos:
         </p>

         <p style="font-size:12px;text-align: justify;">
          <ul>
            <li>Acceso a datos; </li>
            <li>Consulta de buró crediticio; </li>
        </ul>
        </p>

        
      </div>
      <div class="modal__action">

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                <button 
                    type="button" 
                    class="btn btn-primary" 
                    @click="confirmar" 
                    style="width: 100%;"
                >
                    Confirmar
                </button>

            </div>


        </div>
     
      </div>
    </VueFinalModal>

        <h5 class="mt-3" style="text-align: center;margin-bottom: 15px;">¿Cómo deseas ingresar?</h5>

      
    <form role="form"  autocomplete="off" @submit.prevent="onSubmitPhone">
        <div class="mb-3">

        <MazPhoneNumberInput type="tel" default-country-code="EC" style="width: 100%;" no-flags no-example required show-code-on-list
            size="lg" v-model="phoneNumber"  @update="results = $event" :translations="{
            countrySelector: {
                placeholder: 'Código',
                error: 'Elija un pais',
            },
            phoneInput: {
                placeholder: 'Nro. celular',
                example: 'Ejemplo:',
            },
            }" />

        </div>

        <div class="text-center">
        <argon-button :disabled="isActive" id="log-in" ref="log-in"     type="submit" class="mt-1" variant="gradient"  color="success"  style="width: 100%;" size="lg">{{ textoButton }}
            celular</argon-button>
            <div ref="recaptcha-container" id="recaptcha-container" />
        </div>

    </form>


        <div class="text-center">
        <span>~ o ~</span>
        </div>

        <div class="text-center" style="margin-bottom:15px">
        <argon-button class="mt-1" :disabled="isActiveGoogle"  @click="autenticacion('google')" variant="gradient" color="success"  style="width: 100%;" size="lg">{{ textoButton }}
            Gmail</argon-button>
        </div>

        

        <h5 v-if="!isLogin" class="mt-3" style="text-align: center;margin-bottom: 5px;font-size: 16px;">¿Ya tienes una cuenta?</h5>

        <div class="text-center" >
        <argon-button class="mt-1"   @click="contrasena()" variant="gradient" color="success"  style="width: 100%;" size="lg"> Inicia con contraseña</argon-button>
        </div>

   
  </template>
  
  
  <script>
  /*eslint-disable */
  import ArgonButton from "@/components/ArgonButton.vue";
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  import 'firebase/compat/app-check'
  import {  VueFinalModal } from 'vue-final-modal'
   import 'vue-final-modal/style.css'

  export default {
    name: "formulario",
    components: {
        ArgonButton,VueFinalModal
    },
    props: ['promoCode','textoButton','isLogin'],
    data() {
      return {
        passwordFieldTypeNew: 'password',
        isDisabled:false,
        password:"",
        phoneNumber: null,
        results: null,
        phoneNumberPassword:null,
        resultsPassword: null,
        showModal:false,
        showModalContraseña:false,
        showTerminos:false,
            countryCode: '',
        countryCode:"",
        userData:null,
        token: null,
        isPhoneValid:false,
        confirmationResult: null,
            installEvent:null,
            recaptchaVerifier: null,
            recaptchaWidgetId: null,
            confirmResult: null,
        appCheck: null,
        isActiveGoogle:false,
        code:"",
        isActive:true,
        validacion: {
          respuestMovil: false
        },
      }
    },
    watch: {

        results(newValue) {
                    if (newValue.isValid) {
                    this.isActive = false
                    this.phoneNumber = newValue.e164
                    this.phoneNumberPassword=newValue.e164
                    this.countryCode = newValue.countryCode
            
                    } else {
                    this.isActive = true
                    this.countryCode = ''
                    this.code = ''
                    this.phoneNumberPassword="";
                    this.isPhoneValid=false;
                    }
                },

                resultsPassword(newValue) {
                    if (newValue.isValid) {

                    this.phoneNumberPassword=newValue.e164
                    this.countryCode = newValue.countryCode;
                    this.isPhoneValid=true;
            
                    } else {
             
                    this.countryCode = ''
                    this.phoneNumberPassword="";
                    this.isPhoneValid=false;
                    }
                },



        },
        computed: {

          iconEye() {
                return this.passwordFieldTypeNew === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash'
              },

          },
        async mounted() {
          firebase.auth().useDeviceLanguage();

          let user = this.$store.state.user;

     

          if(user){

              let datos_json = JSON.parse(user);
              this.token=datos_json.tokenAuth;


          }else{
  
            
         
            this.appCheck = firebase.appCheck()


            this.appCheck.activate(
            '6LfIe5cpAAAAANAOmaAlMCHHNiLqgSD1WKDMeSy8',

            // Optional argument. If true, the SDK automatically refreshes App Check
            // tokens as needed.
            true,
            )
        
            let tokenAux= await this.appCheck.getToken();
            this.token= tokenAux.token;

        
       

        this.appCheck.setTokenAutoRefreshEnabled(true) 

       }

       this.recaptchaVerifier= new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                                        'size': 'invisible',
                                        'callback': (response) => {
                                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                                        // this.login();
                                    
                                        }
                                    });

        },
    methods: {

      contrasena(){

        this.isDisabled=false;
        this.password="";
        this.showModal=false;
        this.showModalContraseña=true;

      },

      onSubmitPassword(event){
              event.preventDefault();

              if(this.isPhoneValid){

                const userId_json = { dato: this.phoneNumberPassword, password:this.password }
              const user_string = JSON.stringify(userId_json)

          
              const userEncripted = this.$encryptBackoffice.encrypt(user_string)
                this.isDisabled = true;

                document.getElementById('btn_loginPassword').innerHTML = '<img style="margin-left:10px; width:15px " src="/img/loading2.svg" alt="Loading" />  Iniciando sesión'


            this.$https.post('/acceso/loginPassword/', { tokenAuth: this.token, userId: userEncripted }).then(response => {

              document.getElementById('btn_loginPassword').innerHTML = 'Inicia con'

              if (response.data.code == 200) {
                this.userData = response.data.user
                    


   


                localStorage.setItem('userData', JSON.stringify(this.userData))
                            this.$store.state.user= JSON.stringify(this.userData);

                            if(this.isLogin){
                                this.$router.push({ name: 'Dashboard' })
                               
                              }else{
                                location.reload();
                              }





                
              } else {
                     
         

                this.$toast.error(response.data.message, {
                                        position:"top-right",
                                        max: 10
                                        })

                this.isDisabled = false
                 
              }
            }).catch(error => {
              this.isDisabled = false

              this.$toast.error(error, {
                                        position:"top-right",
                                        max: 10
                                        })


                })



      

              }else{

                this.$toast.error("El número celular ingresado es incorrecto para el pais seleccionado", {
                                        position:"top-right",
                                        max: 10
                                        })


            

              }

          


            },

      togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },

        handleOnComplete(value){
            this.verifyCode(value)

        },

        verifyCode(newValue){

         this.showModal=false;
         this.$swal({

            html:
            '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Iniciando sesión</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            position: 'top',
            });


            this.isActive = true
            this.isActiveGoogle = true
            

            this.confirmResult.confirm(newValue)
                .then(resultUser => {

        

                    const { user } = resultUser

                 
                    const userId_json = { userId: user.uid, promoCode:this.promoCode, login: user.phoneNumber }
                 
                    this.$https.post('/acceso/login/', { tokenAuth: this.token, datos: userId_json }).then(response => {

                    this.$swal.close()

               
                    
                
                    if (response.data.code == 200) {

                      

             
                      this.userData = response.data.user
                    
                  




                        let isNewUser= response.data.isNewUser;

            
                    
                    

                        if(isNewUser){

                            this.showTerminos=true;
               

                        }else{
                    

                            localStorage.setItem('userData', JSON.stringify(this.userData))
                            this.$store.state.user= JSON.stringify(this.userData);

                            if(this.isLogin){
                                this.$router.push({ name: 'Dashboard' })
                               
                              }else{
                                location.reload();
                              }

                     
                           
                          
                        }

                        

                    

                    
                    } else {
                        
                        this.isActive = false;
                        this.isActiveGoogle = false;
                        this.showModal=false;
                        this.code = ''

                        this.$toast.error(response.data.message, {
                                        position:"top-right",
                                        max: 10
                                        })
                            

                    }
                    }).catch(error => {
                    this.isActive = false
                    this.code="";
                    this.isActiveGoogle = false;

                    
                    this.$toast.error("3) Ocurrió un error inesperado: " + error, {
                                        position:"top-right",
                                        max: 10
                                        })


            
                    })

                
                })
                .catch(error => {

                    this.$swal.close();




                this.code = ''

                
                const errorCode = error.code;
                

                this.isActive = false
                this.isActiveGoogle = false;

                if (errorCode == 'auth/user-disabled') {

                    this.$toast.error('Lo sentimos, su cuenta se encuentra inhabilitado actualmente', {
                                        position:"top-right",
                                        max: 10
                                        })

                }

                if (errorCode == 'auth/account-exists-with-different-credential') {

                    this.$toast.error('No es posible su logeo o registro porque esta cuenta ya se encuentra registrada', {
                                        position:"top-right",
                                        max: 10
                                        })

           
                }

                if(errorCode == 'auth/invalid-verification-code'){

                    this.showModal=false;

                    
                    this.$toast.error('El código ingresado es incorrecto, por favor intente de nuevo', {
                                        position:"top-right",
                                        max: 10
                                        })

    

                }

            

                })



        },

        closed(){
          this.showModalContraseña=false;


        },

        closed2(){

          this.showModal=false;

        },
        


        onSubmitPhone(event){
                event.preventDefault();

                

                this.isActive = true

                document.getElementById('log-in').innerHTML = '<div style="justify-content: space-between;display: flex;"><i class="fas fa-spinner fa-pulse"></i> Enviando código SMS</div> '


                firebase.auth().signInWithPhoneNumber(this.phoneNumber, this.recaptchaVerifier)
                    .then(confirmationResult => {
                    this.confirmResult = confirmationResult

                    document.getElementById('log-in').innerHTML = this.textoButton+' celular'



                    this.code = '';

                    this.showModal=true;

            
                    this.isActive = false;
                    

                    })
                    .catch(error => {

                      console.log(error);
                    const errorCode = error.code
                    document.getElementById('log-in').innerHTML = this.textoButton+' celular'

                    if (errorCode == 'auth/too-many-requests') {

              


                        this.$toast.warning('Hemos bloqueado todas las solicitudes de este dispositivo debido a actividad inusual', {
                                        position:"top-right",
                                        max: 10
                                        })

                        this.isActive = false
            

                    } else {

                      
                      this.isActive = false

                      if(error.message === "Cannot read properties of null (reading 'verify')"){

                        this.$toast.warning("Token mal generado, se hará un reload de la página", {
                                        position:"top-right",
                                        max: 10
                                        })

                        location.reload();


                        
                      }else{
                        this.$toast.warning(error.message, {
                                        position:"top-right",
                                        max: 10
                                        })
                      }

                   
                    

                        
                    
                    }
                    })

                },


                confirmar(){

                   this.showTerminos=false;
                    localStorage.setItem('userData', JSON.stringify(this.userData))
  
                    this.$store.state.user= JSON.stringify(this.userData);
                    if(this.isLogin){
                                this.$router.push({ name: 'Dashboard' })
                               
                              }else{
                                location.reload();
                              }

                },
  
       
      autenticacion(type) {
        let provider = ''
  
        if (type == 'google') {
          provider = new firebase.auth.GoogleAuthProvider()
          this.isActiveGoogle = true
          
        } else {
          provider = new firebase.auth.FacebookAuthProvider()
        }
  
        firebase.auth()
          .signInWithPopup(provider)
          .then(result => {
            const { user } = result
  
        
           
              const userId_json = { userId: user.uid, promoCode:this.promoCode, login: user.email }
           
                this.isActive = true;
  
             
     
                this.$swal({
  
                    html:
                    '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Iniciando sesión</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    position: 'top',
                    });
  
  
              this.$https.post('/acceso/login/', { tokenAuth: this.token, datos: userId_json }).then(response => {
                this.$swal.close()
              
                if (response.data.code == 200) {
            
  
                  this.userData = response.data.user
  
               
                  let isNewUser= response.data.isNewUser;

            
                                        
                                        

                    if(isNewUser){

                        this.showTerminos=true;
                    }else{

               
                         
                         localStorage.setItem('userData', JSON.stringify(this.userData))
                         this.$store.state.user= JSON.stringify(this.userData);
     
                         if(this.isLogin){
                                this.$router.push({ name: 'Dashboard' })
                               
                              }else{
                                location.reload();
                              }
                    }

  
                  
                } else {
                    this.$toast.error(response.data.message, {
                                        position:"top-right",
                                        max: 10
                                        })
                       
                
  
                  this.isActiveGoogle = false
                   
                }
              }).catch(error => {

                this.$toast.error("2) Ocurrió un error inesperado: " + error, {
                                        position:"top-right",
                                        max: 10
                                        })
                       
              
              })
      
          }).catch(error => {
            // Handle Errors here.
  
            const errorCode = error.code;
  
         
  
            this.isActiveGoogle = false
  
            if (errorCode == 'auth/popup-closed-by-user') {
  
            }
  
            if (errorCode == 'auth/user-disabled') {

                this.$toast.error('Lo sentimos, su cuenta se encuentra inhabilitado actualmente', {
                                        position:"top-right",
                                        max: 10
                                        })

      
            }
  
            if (errorCode == 'auth/account-exists-with-different-credential') {

                this.$toast.error('No es posible su logeo o registro porque esta cuenta ya se encuentra registrada', {
                                        position:"top-right",
                                        max: 10
                                        })
        
            }
  
            // ...
          })
      },
  
    },
    created() {
  
  
    },
    beforeUnmount() {

    },
  };
  </script>
  
  <style scoped>



  .bg-gradient-success {
    background-image: linear-gradient(310deg, #591361  0%, #7d0c7e 100%);
  
  }

  
  
  
  .m-input.--md .m-input-input, .m-input.--md .m-input-label {
      font-size: 12px !important;
      line-height: 1.5rem;
  }
  
  .btn:disabled, .btn.disabled, fieldset:disabled .btn {
      cursor: not-allowed;
      pointer-events: auto;
  }
  

  </style>
  