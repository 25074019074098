<template>


            <div class="card" style="margin-bottom:10px">

              <div class=" card-body">

                <div class="row">

                  <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" style="margin-bottom:10px;  justify-content: space-between;display: flex;">
                  
                    <h5 class="card-title mb-0 text-uppercase font-weight-bold" style="font-size: 12px;">TU EQUIPO PRIMER NIVEL</h5>
                    <div  v-if="!loading" @click="refresh"  style="cursor:pointer;position: relative; top:-5px"> 
                        <i  style="font-size: 6px" class="fas fa-sync text-sm opacity-10"></i>
                      </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-12">
                    <Skeletor v-if="loading" :shimmer="true" height="38" width="50%" style="margin-bottom:0px"/>
                    <p v-else class="font-weight-bolder priceCard">{{ cantidad }}</p>
                  </div>


                 

                </div>
              </div>

            </div>
  

          
    </template>
    
    <script>
    
    /* eslint-disable */
    export default {
    components: {
        
    
    
    
    },
    props: ['userId', 'tokenAuth'],
    data() {
    
    
        return {
            loading: true,
            cantidad:0
    
        }
    },
    computed: {
    
    },
    watch: {
    
    },
    mounted() {
    
        this.getReferidos();
    
    },
    methods: {
    
        referidos(){
            this.$toast.error("Funcionalidad no disponible por el momento", {
                                        position:"top-right",
                                        max: 10
                                        })
        },
        refresh() {
                this.$eventBus.emit('reiniciarPrimerNivel')
              },
    
              getReferidos(){

                    const userId_json = { userId: this.userId, type:"Primero" }
                    const user_string = JSON.stringify(userId_json)
    
                    const userIdEncripted = this.$encryptBackoffice.encrypt(user_string)
                 
                    this.$https.post('/referidos/getNumberReferidos/', { tokenAuth: this.tokenAuth, data: userIdEncripted }).then(response => {
    
    
    
                    if (response.data.code == 200) {
                    this.loading = false;
    
    
      
                    this.cantidad= response.data.cantidad;
    
                    } else {
    
    
    
    
                    if (response.data.code == 401) {
    
          
                    
                            localStorage.removeItem('userData')
this.$store.state.user="";
    
    
                         // Redirect to login page
                         this.$router.push({ name: 'login' })
                    } else {
                            this.getReferidos();
                    }
                    }
                    }).catch(error => {
                              this.getReferidos();
                    })
                    },
    
    
    
        
    
    },
    }
    </script>
    
    <style lang="scss">
    
    
    
    </style>
          