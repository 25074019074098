<template>
  <VueFinalModal
    v-model="showModal"
    :esc-to-close="true"
    class="modal-container"
    content-class="modal-content"
  >
    <div class="modal__content">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; flex-direction: column">
          <label>Ingresado el: </label>
          <p
            class="form-control"
            style="margin: 0; padding: 0.2rem 0.5rem; width: fit-content"
          >
            {{ venta.dateFormated }}
          </p>
        </div>
        <div style="display: flex; flex-direction: column; gap: 8px">
          <label style="margin-bottom: 0">Recordatorio: </label>
          <input
            type="datetime-local"
            class="form-control"
            v-model="reminderDate"
            style="width: fit-content; margin: 0; padding: 0.2rem 0.5rem"
          />
          <input
            type="text"
            class="form-control"
            v-model="reminderNote"
            style="margin: 0; padding: 0.2rem 0.5rem"
            placeholder="Ingrese Nota"
          />
          <button
            class="btn btn-primary"
            id="buttonAddReminder"
            style="
              padding: 0.4rem 0.625rem;
              width: fit-content;
              align-self: end;
            "
            @click="addReminder({ reminderDate, reminderNote })"
            :disabled="isAdding"
          >
            Agregar
          </button>
        </div>
      </div>
      <label>Promotor: </label>
      <p class="form-control" style="margin: 0; padding: 0.2rem 0.5rem">
        {{ venta.quienRefiere }}
      </p>
      <label v-if="venta.phoneRefiere"> Llamar Promotor:</label>
      <p
        class="form-control"
        v-if="venta.phoneRefiere"
        style="font-weight: 600; margin: 0; padding: 0.2rem 0.5rem"
      >
        <a href="tel:venta.phoneRefiere"> {{ venta.phoneRefiere }}</a>
      </p>
      <label>Cliente: </label>
      <p class="form-control" style="margin: 0; padding: 0.2rem 0.5rem">
        {{ venta.displayName }}
      </p>
      <label>Estado: </label>
      <p class="form-control" style="margin: 0; padding: 0.2rem 0.5rem">
        {{ venta.estado }}
      </p>
      <div v-if="venta.notas">
        <label>Notas:</label>
        <div
          v-for="(nota, index) in venta.notas"
          :key="index"
          class="notesContainer"
        >
          <p style="margin: 0 0 0 8px; display: flex; gap: 8px">
            <span style="font-size: 10px; align-self: center">{{
              index + 1
            }}</span>
            {{ nota.nota }}
          </p>
          <p
            style="
              margin: 0 8px 0 0;
              font-style: italic;
              font-size: 11px;
              color: #979797;
            "
          >
            {{ nota.fecha }}
          </p>
        </div>
      </div>
      <label>Agregar Nota: </label>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 6px;
        "
      >
        <input
          v-model="note"
          class="form-control"
          style="margin: 0; padding: 0.2rem 0.5rem"
          placeholder="Ingresar anotación"
        />
        <button
          class="btn btn-primary"
          id="buttonAdd"
          style="padding: 0.4rem 0.625rem; width: fit-content; align-self: end"
          :disabled="isButtonDisabled"
          @click="addNote({ userId: venta.userId, id: venta.id, nota: note })"
        >
          Agregar
        </button>
      </div>
    </div>
  </VueFinalModal>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> LISTADO DE
            INTERESADOS PENDIENTES EN CONTRATAR XTRIM
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="notAllow" class="row">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <img style="width: 100px" src="/img/caution.svg" />

          <p style="margin-top: 10px; font-size: 14px">
            Tu rol no te permite utilizar la siguiente funcionalidad.
          </p>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th>Fecha/Hora</th>
            <th>Quien Refiere</th>
            <th>Celular Referidor</th>
            <th data-priority="1">Nombres y apellidos</th>
            <th>DNI</th>
            <th>Celular</th>
            <th>Email</th>
            <th>Canal</th>
            <th>Coordenadas</th>
            <th data-priority="2">Estado</th>
            <th data-priority="3">Notas</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
    VueFinalModal,
  },
  props: ["userId", "tokenAuth", "idAdmin"],
  data() {
    let userId = JSON.parse(this.$store.state.user).userId;
    return {
      loading: true,
      transactions: [],
      notAllow: false,
      showModal: false,
      venta: null,
      note: null,
      reminderNote: null,
      isAdding: false,
      reminderDate: null,
      adminId: userId,
      notas: [
        { nota: "Cliente no contesta", date: "22/07/2024, 20:36" },
        { nota: "Cliente contestó", date: "22/07/2024, 20:36" },
        { nota: "Dijo que llame más tarde", date: "22/07/2024, 20:36" },
        { nota: "Interesado", date: "22/07/2024, 20:36" },
        { nota: "No interesado", date: "22/07/2024, 20:36" },
        { nota: "Ya tiene servicio", date: "22/07/2024, 20:36" },
        { nota: "Quiere más información", date: "22/07/2024, 20:36" },
        { nota: "Cita programada", date: "22/07/2024, 20:36" },
        { nota: "Cliente ocupado", date: "22/07/2024, 20:36" },
      ],
      columns: [
        { data: "dateFormated" },
        { data: "quienRefiere" },
        { data: "phoneRefiere" },
        { data: "displayName" },
        { data: "dni" },
        { data: "phone" },
        { data: "email" },
        {
          data: null,
          render: function (data, type, row, meta) {
            if (row.canal ?? row.canal === "WhatsApp") {
              return '<span style="background: #28a745; color: white;" class="badge badge-success">WhatsApp</span>';
            } else {
              return '<span style="width:65px" class="badge badge-secondary">Web</span>';
            }
          },
        },
        {
          data: null,
          render: function (data, type, row, meta) {
            console.log("data coordinates", data);
            return `${
              row.coordinates
                ? `${row.coordinates._latitude}  ${row.coordinates._longitude}`
                : ""
            }`;
          },
        },
        {
          data: null,
          render: function (data, type, row, meta) {
            return `
            <div class="dropdown">
            <button class="btn btn-primary btn-sm dropdown-toggle " type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                ${data.estado}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li><button data-id="${data.id}" data-estado="INSTALADO: Contrato instalado"  class="aprobar dropdown-item" type="button">INSTALADO: Contrato instalado</button></li>
                <li><button data-id="${data.id}" data-estado="NO DESEA"  class="aprobar dropdown-item" type="button">NO DESEA</button></li>
                <li><button data-id="${data.id}" data-estado="SIN COBERTURA / NO APLICA"  class="aprobar dropdown-item" type="button">SIN COBERTURA / NO APLICA</button></li>
                <li><button data-id="${data.id}" data-estado="EN NEGOCIACIÓN"  class="aprobar dropdown-item" type="button">EN NEGOCIACIÓN</button></li>
                <li><button data-id="${data.id}" data-estado="NO CONTESTA"  class="aprobar dropdown-item" type="button">NO CONTESTA</button></li>
                <li><button data-id="${data.id}" data-estado="PEND INSTALACIÓN"  class="aprobar dropdown-item" type="button">PEND INSTALACIÓN</button></li>
                <li><button data-id="${data.id}" data-estado="INCONTACTABLE"  class="aprobar dropdown-item" type="button">INCONTACTABLE</button></li>
                <li><button data-id="${data.id}" data-estado="CLIENTE EXISTENTE"  class="aprobar dropdown-item" type="button">CLIENTE EXISTENTE</button></li>
                <li><button data-id="${data.id}" data-estado="CONTRATA POR OTRO CANAL"  class="aprobar dropdown-item" type="button">CONTRATA POR OTRO CANAL</button></li>
                <li><button data-id="${data.id}" data-estado="TERCERA EDAD"  class="aprobar dropdown-item" type="button">TERCERA EDAD</button></li>
                <li><button data-id="${data.id}" data-estado="PENDIENTE DOCUMENTACIÓN"  class="aprobar dropdown-item" type="button">PENDIENTE DOCUMENTACIÓN</button></li>
                <li><button data-id="${data.id}" data-estado="BANDEJA DE CALIDAD"  class="aprobar dropdown-item" type="button">BANDEJA DE CALIDAD</button></li>
                </ul>
            </div>`;
          },
        },
        {
          data: null,
          render: function (data, type, row, meta) {
            const notas = data.notas ? data.notas : "No hay notas";
            const dataString = JSON.stringify(data).replace(/"/g, "&quot;"); // Escape double quotes
            return `
              <div>
                <button type="button"   class="btn btn-primary btn-sm toggle-notes" data-transaction-id="${data.id}" data-venta="${dataString}">
                  <p style="color: white; font-weight: 600; font-size: 12px; margin: 0; width: max-content;">Ver Notas</p>
                </button>
              </div>`;
          },
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [7, 15, 25, 35, 50, -1],
          [7, 15, 25, 35, 50, "All"],
        ],
        pageLength: 7,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {
    isButtonDisabled() {
      return !this.note || this.isAdding;
    },
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.note = "";
        this.reminderDate = null;
        this.reminderNote = null;
        this.venta = null;
      }
    },
  },
  mounted() {
    $(document).off("click", ".aprobar");

    $(document).on("click", ".aprobar", (evt) => {
      const id = $(evt.target).data("id");
      const estado = $(evt.target).data("estado");

      this.cambiarEstado(id, estado);
    });
    const vm = this;
    $(document).ready(function () {
      $(document).on("click", ".toggle-notes", function () {
        const venta = $(this).data("venta");
        vm.venta = venta;
        vm.showModal = true;
      });
    });

    this.getListado();
  },
  methods: {
    cambiarEstado(id, estado) {
      this.$swal({
        html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Cambiando estado</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: "top",
      });

      this.$https
        .post("/administracion/updateVenta/", {
          tokenAuth: this.tokenAuth,
          id: id,
          estado: estado,
          userId: this.userId,
          idAdmin: this.idAdmin,
        })
        .then((response) => {
          this.$swal.close();

          if (response.data.code == 200) {
            this.refresh();
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });
          } else {
            this.refresh();
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code === 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("2) Ocurrió un error inesperado: " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    consultarStatus(id) {
      this.$swal({
        html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: "top",
      });

      this.$https
        .post("/ventas/consultarVenta/", { tokenAuth: this.tokenAuth, id: id })
        .then((response) => {
          this.$swal.close();

          if (response.data.code == 200) {
            this.refresh();
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });
          } else {
            this.refresh();
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code === 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("2) Ocurrió un error inesperado: " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    refresh() {
      $(document).off("click", ".consultar");
      this.$eventBus.emit("reiniciarListadoVentasAdmin");
    },

    getListado() {
      const data_json = { userId: this.userId, idAdmin: this.idAdmin };
      const data_string = JSON.stringify(data_json);

      const dataEncripted = this.$encryptBackoffice.encrypt(data_string);
      this.$https
        .post("/administracion/getVentasPendientes/", {
          tokenAuth: this.tokenAuth,
          data: dataEncripted,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.loading = false;
            this.notAllow = false;
            this.transactions = response.data.transactions;
          } else {
            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            } else {
              if (response.data.code == 403) {
                this.$router.push({ name: "Dashboard" });
              } else {
                if (response.data.code == 404) {
                  this.loading = false;
                  this.notAllow = false;
                } else {
                  if (response.data.code === 501) {
                    this.loading = false;

                    this.notAllow = true;
                  } else {
                    this.getListado();
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          this.getListado();
        });
    },
    addNote(body) {
      this.isAdding = true;
      document.getElementById("buttonAdd").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Agregando...';
      this.$https
        .post("/administracion/getNotasAdd/", body)
        .then((response) => {
          this.note = null;
          this.showModal = false;
          this.isAdding = false;
          this.getListado();
          this.$toast.success(response.data.message, {
            position: "top-right",
            max: 10,
          });
          document.getElementById("buttonAdd").innerHTML = "Agregar";
        })
        .catch((error) => {
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
          document.getElementById("buttonAdd").innerHTML = "Agregar";
          this.note = null;
          this.showModal = false;
          this.isAdding = false;
          console.log(error);
        });
    },
    addReminder(body) {
      this.isAdding = true;
      document.getElementById("buttonAddReminder").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Agregando...';
      let date = null;
      let time = null;
      let noteToAdd = `Cliente ${this.venta.displayName} - ${body.reminderNote} - ${this.venta.phone}`;

      if (body.reminderDate) {
        // Split by 'T' to separate date and time
        const datetime = this.reminderDate.split("T");
        time = datetime[1];
        date = datetime[0].split("-").reverse().join("-");
      }

      this.$https
        .post("/recordatorio/getAddSaved", {
          fecha: date,
          hora: time,
          note: noteToAdd,
          userId: this.adminId,
        })
        .then((response) => {
          this.showModal = false;
          this.isAdding = false;
          this.getListado();
          this.$toast.success(response.data.message, {
            position: "top-right",
            max: 10,
          });
          document.getElementById("buttonAddReminder").innerHTML = "Agregar";
        })
        .catch((error) => {
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
          document.getElementById("buttonAddReminder").innerHTML = "Agregar";
          this.note = null;
          this.showModal = false;
          this.isAdding = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

.notesContainer {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
}

.notesContainer:hover {
  background-color: #f2f2f2;
  border-radius: 5px;
}
</style>
