<template>
    <div class="container-fluid">

        <div class="row" style="width: 100%;margin:0px">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="height: 200px;display:block;    margin: 0px !important">

                    <div style=" justify-content: space-between;display: flex;">

                        <img src="/img/amarillo.svg" style="width: 125px;" />

                        <button @click="exit"
                            style="background-color: white; color: white; padding: 7px 20px; border: none; border-radius: 50px; ">
                            <i class="fas fa-sign-out-alt text-success text-sm opacity-10" style="font-size: 6px"></i> <span
                                style="color:#7d0c7e; font-size: 12px; font-weight: bold">Cerrar Sesión</span>
                        </button>
                    </div>

                    <p style="text-align: center; color:white; font-weight: bold;font-size:23px; margin-bottom: 0px;margin-top: 30px;    line-height: 28px;">Sólo un paso más... ¡Registra una contraseña!</p>

                </nav>

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"></div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" style=" margin-top: -55px; ">

                <div class="card">

                            <div class="card-body" style="padding:10px !important">
                                <!-- contenido de la tarjeta -->


                                        <p style="text-align: center;margin-bottom:10px; font-weight: bold; font-size:16px">Registro de contraseña</p>
                                      
                                        <div class="alert alert-primary" role="alert" style="    margin: 0px;font-size:12px">
                                            <p style="text-align:justify;font-size:12px;    margin-bottom: 10px;font-weight: bold;">Su contraseña debe cumplir con los siguientes requisitos:</p>

                                            <p class="frmValidation" :class="{'frmValidation--passed' : has_minLongitud == true}">

                                                <i style="font-size:16px" :class="[has_minLongitud == true ? 'color_green' : 'color_red', icon1]"></i>

                                            


                                                Debe tener una longitud mínima de 9 caracteres.
                                                </p>
                                                <p class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }">

                                                    <i style="font-size:16px" :class="[has_uppercase == true ? 'color_green' : 'color_red', icon2]"></i>

                                                Debe tener al menos una letra en mayúscula.</p>
                                                <p class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }">

                                                    <i style="font-size:16px" :class="[has_lowercase == true ? 'color_green' : 'color_red', icon3]"></i>

                                                    Debe tener al menos una letra en minúscula.</p>
                                                <p class="frmValidation" :class="{'frmValidation--passed' : has_number }">

                                                    <i style="font-size:16px" :class="[has_number == true ? 'color_green' : 'color_red', icon4]"></i>


                                            
                                                Debe tener al menos un número.</p>
                                                <p class="frmValidation" :class="{'frmValidation--passed' : has_special }">
                                                    <i style="font-size:16px" :class="[has_special == true ? 'color_green' : 'color_red', icon5]"></i>

                                                Debe tener al menos un caracter especial.</p>

                                        </div>

                                        <form role="form" autocomplete="off" @submit.prevent="registerPassword">

                                            <div class="row"> 

                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 

                                                    <label for="example-text-input" class="form-control-label"
                                                        style="font-weight: bold; font-size: 12px; ">Ingrese una contraseña (*):</label>
                                                    <div class="input-group mb-3">
                                                        <input  required 
                                                        ref="password"
                                                            class="form-control" 
                                                            :type="passwordFieldTypeNew"
                                                            @input="password_check"
                                                            @paste.prevent
                                                            placeholder="Ingrese su contraseña"
                                                            v-model="password"
                                                            aria-describedby="button-addon2">
                                                        <button type="button" class="btn-outline-secondary btn2"  @click="togglePasswordNew">

                                                            <i style="font-size:14px" :class="iconEye"></i>


                                                        
                                                        </button>

                                                    </div>

                                                    <button type="submit" class="btn btn-primary" style="width: 100%;" :disabled="isDisabled"> Actualizar mi perfil </button>

                                                </div>



                                            </div>



                                        </form>
                                    


                            </div>
                            </div>

            </div>



          

            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"></div>



            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

</div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center align-items-center justify-content-xl-between"
            style="color:black !important;margin-top: 15px; ">
            <hr class="horizontal dark" />


            <a
              target="_blank"
              title="Facebook de XTrimPro"
              href="https://www.facebook.com/profile.php?id=100090370913056"
            >   <i class="fab fa-facebook-f redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Instagram de XTrimPro"
              href="https://www.instagram.com/xtrimpro.ec/"
            > <i class="fab fa-instagram redes" aria-hidden="true"></i>  </a>
            <a
              target="_blank"
              title="Email de XTrimPro"
              href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
            > <i  class="fas fa-envelope redes" aria-hidden="true"></i> </a>
            <a
              target="_blank"
              title="Tiktok de XTrimPro"
              href="https://www.tiktok.com/@xtrimproec"
            > <svg
            style="margin-bottom: 10px;"
              xmlns="http://www.w3.org/2000/svg"
              fill="#7d0c7e"
              widht="10"
              height="22"
              viewBox="0 0 448 512"
            ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
              href="https://firebasestorage.googleapis.com/v0/b/tvcable-bebde.appspot.com/o/Documentos%2Fterminos_final.pdf?alt=media&token=cce18811-7832-444e-b970-ca695026fc3b"
              target="_blank"
            >Términos y condiciones</a> -  <a
              href="https://firebasestorage.googleapis.com/v0/b/tvcable-bebde.appspot.com/o/Documentos%2Fpolitica-xtrim.pdf?alt=media&token=eb01125b-962e-400d-baef-ae98fd1cab20"
              target="_blank"
            >Política de privacidad</a> -  <a
              href="https://firebasestorage.googleapis.com/v0/b/tvcable-bebde.appspot.com/o/Documentos%2Faviso_legal_xtrimpro.pdf?alt=media&token=c456d36d-8168-4c00-96b3-751b1930f985"
              target="_blank"
            >Aviso legal</a>-  <a
              href="https://firebasestorage.googleapis.com/v0/b/tvcable-bebde.appspot.com/o/Documentos%2FDisclaimer-xtrim.pdf?alt=media&token=0b1580da-3e6d-406f-965c-bc8567b0dbd5"
              target="_blank"
            >Disclaimer</a></p>

            <p style="margin-top: 0px; font-size: 12px;    margin-bottom: 10px;"> Copyright &copy; {{ year }} <a
              href="https://xtrimproec.com"
              target="_blank"
            >- XTrimProEC</a></p>

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">

</div>

        </div>

    </div>
</template>
  
  
<script>
/*eslint-disable */


import myMixin from '../mixin.js'


export default {
    name: "Password",
    data() {

        const { logout } = myMixin()

        const datos_json = JSON.parse(this.$store.state.user);


       
        return {
            logout: logout,
            isDisabled: true,
            year: new Date().getFullYear(),
            userId:datos_json.userId,
            tokenAuth: datos_json.tokenAuth,
             displayName: datos_json.displayName,
             phone:datos_json.phone,
                 password:  '',
                  has_number:    false,
                  has_lowercase: false,
                  has_uppercase: false,
                  has_special:   false,
                  has_minLongitud:   false,
                  passwordFieldTypeNew: 'password',
                  icon1: "fas fa-times-circle",
                  icon2: "fas fa-times-circle",
                  icon3: "fas fa-times-circle",
                  icon4: "fas fa-times-circle",
                  icon5: "fas fa-times-circle",
  
   
           
        }
    },
    methods: {
        togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
        password_check(){

         

            if(Number(this.password.length) >= Number("9")){
            this.has_minLongitud=true;
            this.icon1="fas fa-check-circle"
            }else{
            this.has_minLongitud=false;
            this.icon1="fas fa-times-circle"
            }
            this.has_number    = /\d/.test(this.password);
            if(this.has_number){
            this.icon4="fas fa-check-circle"
            }else{
            this.icon4="fas fa-times-circle"
            }
            this.has_lowercase = /[a-z]/.test(this.password);
            if(this.has_lowercase){
            this.icon3="fas fa-check-circle"
            }else{
            this.icon3="fas fa-times-circle"
            }
            this.has_uppercase = /[A-Z]/.test(this.password);
            if(this.has_uppercase){
            this.icon2="fas fa-check-circle"
            }else{
            this.icon2="fas fa-times-circle"
            }
            this.has_special   = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
            if(this.has_special){
            this.icon5="fas fa-check-circle"
            }else{
            this.icon5="fas fa-times-circle"
            }

            if(this.has_minLongitud &&  this.has_number &&  this.has_lowercase &&  this.has_uppercase &&  this.has_special ){ 
            this.isDisabled= false;

            }else{
            this.isDisabled= true;
            }
            },

        exit() {

         
            
            this.logout(this.userId, this.token, true);

        },

      
        registerPassword(event) {
        event.preventDefault();

            let mensaje="";
    
     
                                

                        if(!this.has_minLongitud){
                        mensaje="Su contraseña debe tener una longitud mínima de 9 caracteres.";
                        }

                        if(!this.has_number){
                        mensaje="Su contraseña debe tener al menos un número.";
                        }


                        if(!this.has_lowercase){
                        mensaje="Su contraseña debe tener al menos una letra en minúscula.";
                        }
                    
                        if(!this.has_uppercase){
                        mensaje="Su contraseña debe tener al menos una letra en mayúscula.";
                        }
                    
                        if(!this.has_special){
                        mensaje="Su contraseña debe  tener al menos un caracter especial.";
                        }

                        if(this.has_minLongitud &&  this.has_number &&  this.has_lowercase &&  this.has_uppercase &&  this.has_special ){ 


                        

                        this.$swal({
                                        title: "¿Estas seguro?",
                                        text: `La contraseña que ingresaste es: ${this.password}`,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: "Confirmar contraseña",
                                        cancelButtonText: 'Cancelar',
                                        customClass: {
                                        confirmButton: 'btn btn-primary',
                                        cancelButton: 'btn btn-outline-secondary ml-1',
                                        },
                                        buttonsStyling: false,
                                        showLoaderOnConfirm: true,
                                        preConfirm: () => {
                                    

                                                    
                                                            
                                            const data_json = { userId: this.userId, password: this.password }
                                            const data_string = JSON.stringify(data_json)



                                            const dataEncripted = this.$encryptBackoffice.encrypt(data_string);

                                        return this.$https.post('/profile/registerPassword/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                                            this.$swal.showValidationMessage(
                                            error,
                                            )
                                        })
                                        },
                                        allowOutsideClick: () => !this.$swal.isLoading(),
                                        }).then(result => {

                                        this.$swal.close()

                                        if(result.isConfirmed){
                                        
                                            

                                        if (result.value.code == 200) {

                                            this.$toast.success(result.value.message, {
                                                    position: "top-right",
                                                                    max: 10
                                                });
                            
                                     



                                                const datos_json = JSON.parse(this.$store.state.user);
                                                    
                                                
                                                
                                                    datos_json.hasPassword = true
                                                    localStorage.setItem('userData', JSON.stringify(datos_json))
                                                    this.$store.state.user= JSON.stringify(datos_json);
                                            
                                                this.$router.push({ name: 'Dashboard' })




                                                } else {

                                                    
                                                    this.$toast.error(result.value.message, {
                                                    position: "top-right",
                                                                    max: 10
                                                });
                            
                                         
                            
                                                if (result.value.code == 401) {
                                                    
                                                        
                                                        localStorage.removeItem('userData')


                                                        this.$store.state.user="";


                                            // Redirect to login page
                                            this.$router.push({ name: 'login' })
                            
                                                } 

                                                }

                                        }





                                        })



                        
                    
                        }else{

                            this.$toast.error(mensaje, {
                                                    position: "top-right",
                                                                    max: 10
                                                });
                            

   
                        }


        },

    },
    computed: {

        iconEye() {
              return this.passwordFieldTypeNew === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash'
            },

    },
    mounted() {

        this.$refs.password.focus()


    },
    created() {


    },
    beforeUnmount() {
    
    },
};
</script>
  
<style scoped>

.container-fluid {
    padding: 0px;
    margin: 0px;
}

.bg-dark {
    background: linear-gradient(87deg, #591361, #7d0c7e) !important;
    height: 160px;
}


</style>
  