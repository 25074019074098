<template>
  <div class="align-items-center text-center">
    <p style="margin-bottom: 10px; margin-top: 10px; font-weight: bold">
      Paso {{ step }} de 2 - {{ textStep }}
    </p>
  </div>

  <form
    v-if="firstStep"
    role="form"
    autocomplete="off"
    @submit.prevent="onSubmitFirstStep"
  >
    <div class="row" v-if="!notSupport">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 alert alert-primary"
        role="alert"
        style="
          margin: 0px;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          text-align: center;
        "
      >
        Necesitamos comprobar si en la ubicación donde deseas instalar XTRIM
        tenemos cobertura de nuestra red, por favor indícanos la
        dirección/ubicación donde deseas instalar
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Dirección de su domicilio (*):</label
        >

        <GMapAutocomplete
          :value="autocomplete"
          placeholder="Dirección domiciliaria"
          @place_changed="getAddressData($event)"
          required
          :readonly="isDisabled"
          class="form-control col-lg-12"
        >
        </GMapAutocomplete>
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Indique la ubicación de su domicilio (*):</label
        >

        <GMapMap
          @click="mark"
          :center="center"
          :zoom="16"
          map-type-id="terrain"
          style="width: 100%; height: 250px"
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            disableDefaultUi: true,
          }"
        >
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
            @dragend="handleMarkerDrag"
          />
        </GMapMap>
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        align="center"
      >
        <button
          type="submit"
          id="btn_solicitar"
          class="btn btn-primary"
          style="width: 100%"
          :disabled="isDisabled"
        >
          Siguiente
          <i style="margin-left: 10px" class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>

    <div v-else class="row">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        align="center"
      >
        <svg
          width="100"
          height="100"
          viewBox="-20 0 190 190"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z"
            fill="#4a4a4a"
          />
        </svg>

        <div
          class="alert alert-primary"
          role="alert"
          style="margin: 0px; font-size: 12px"
        >
          <p style="text-align: center; font-size: 14px; margin: 0px">
            Tu navegador no soporta la API de geolicación. Por favor intente con
            el navegador Chrome, Brave, Mozilla u otro.
          </p>
        </div>
      </div>
    </div>
  </form>

  <form v-else role="form" autocomplete="off" @submit.prevent="onSubmit">
    <div class="row">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Nro. de cédula (*):</label
        >
        <input
          placeholder="Ingrese su cédula"
          @blur="checkDNI"
          class="form-control col-lg-12"
          required
          type="tel"
          @keypress="NumbersOnly"
          autocomplete="off"
          :readonly="isDisabled"
          v-model="dni"
        />
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Nombres y apellidos</label
        >
        <input
          required
          placeholder="Nombres y apellidos"
          class="form-control col-lg-12"
          type="text"
          autocomplete="off"
          v-model="displayName"
        />
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Ingrese su número celular (*):</label
        >

        <MazPhoneNumberInput
          type="tel"
          default-country-code="EC"
          style="width: 100%"
          no-flags
          no-example
          required
          show-code-on-list
          size="md"
          v-model="phoneNumber"
          @update="results = $event"
          :translations="{
            countrySelector: {
              placeholder: 'Código',
              error: 'Elija un pais',
            },
            phoneInput: {
              placeholder: 'Nro. celular',
              example: 'Ejemplo:',
            },
          }"
        />
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Ingrese su email (*):</label
        >
        <input
          placeholder="Ingrese su email"
          class="form-control col-lg-12"
          required
          type="email"
          autocomplete="off"
          v-model="email"
        />
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        align="center"
        style="justify-content: space-between; display: flex"
      >
        <button @click="atras" class="btn btn-secondary" :disabled="isDisabled">
          <i style="margin-right: 10px" class="fas fa-arrow-circle-left"></i>
          Atras
        </button>

        <button
          type="submit"
          id="btn_submit"
          class="btn btn-primary"
          :disabled="isDisabled"
        >
          Solicitar servicio
        </button>
      </div>
    </div>
  </form>
</template>

<script>
/*eslint-disable */

export default {
  name: "Venta",
  components: {},
  props: ["promoCode", "tokenAuth"],
  data() {
    return {
      isDisabled: false,
      displayName: "",
      dni: "",
      dniRespaldo: "",
      step: 1,
      phoneNumber: null,
      firstStep: true,
      results: null,
      hasPhone: false,
      email: "",
      textStep: "Ubicación de domicilio",
      Ubication: [],
      center: {},
      markers: [],
      geocoder: null,
      loadMap: true,
      plusCode: "",
      notSupport: false,
      city: "",
      autocomplete: "",
      emailRespaldo: "",
      phoneRespaldo: "",
      idVenta: "",
    };
  },
  methods: {
    getAddressData(request) {
      console.log("entro 1");

      this.autocomplete = request.formatted_address;

      let ubicacion = request.geometry.location;
      this.center = {
        lat: parseFloat(request.geometry.location.lat()),
        lng: parseFloat(request.geometry.location.lng()),
      };

      this.markers = [
        {
          position: ubicacion,
        }, // Along list of clusters
      ];

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            console.log("entro 2");

            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    loadMapa() {
      if (this.loadMap) {
        this.loadMap = false;

        if (!("geolocation" in navigator)) {
          this.$toast.error(
            "La geolocalización no está soportado para este navegador",
            {
              position: "top-right",
              max: 10,
            }
          );

          this.notSupport = true;

          return;
        }

        // get position
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.center = {
              lat: parseFloat(pos.coords.latitude),
              lng: parseFloat(pos.coords.longitude),
            };

            this.markers = [
              {
                position: {
                  lat: parseFloat(pos.coords.latitude),
                  lng: parseFloat(pos.coords.longitude),
                },
              }, // Along list of clusters
            ];

            this.geocoder
              .geocode({ location: this.center })
              .then((response) => {
                if (response.results[0]) {
                  console.log("entro 3");

                  this.plusCode = response.results[0].formatted_address;
                  this.autocomplete = response.results[1].formatted_address;
                  this.city =
                    response.results[0].address_components[2].long_name;
                }
              })
              .catch((e) => console.log("Geocoder failed due to: " + e));
          },
          (err) => {
            this.$toast.error(err.message, {
              position: "top-right",
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        );
      }
    },

    instanceMap() {
      this.geocoder = new google.maps.Geocoder();

      setTimeout(() => this.loadMapa(), 2000);
    },
    handleMarkerDrag(e) {
      this.center = {
        lat: parseFloat(e.latLng.lat()),
        lng: parseFloat(e.latLng.lng()),
      };

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            console.log("entro 4");

            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    mark(event) {
      this.center = {
        lat: parseFloat(event.latLng.lat()),
        lng: parseFloat(event.latLng.lng()),
      };

      this.markers = [
        {
          position: {
            lat: parseFloat(event.latLng.lat()),
            lng: parseFloat(event.latLng.lng()),
          },
        }, // Along list of clusters
      ];

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            console.log("entro 5");

            this.plusCode = response.results[0].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
            this.autocomplete = response.results[1].formatted_address;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    onSubmit(event) {
      event.preventDefault();

      if (this.hasPhone) {
        document.getElementById("btn_submit").innerHTML =
          '<i class="fas fa-spinner fa-pulse"></i>  Solicitando';

        this.isDisabled = true;

        const user_json = {
          promoCode: this.promoCode,
          dni: this.dni,
          displayName: this.displayName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          address: this.autocomplete,
          latitude: this.center.lat,
          longitude: this.center.lng,
          plusCode: this.plusCode,
          city: this.city,
        };

        this.$https
          .post("/ventas/registerVentaFinal/", {
            tokenAuth: this.tokenAuth,
            user: user_json,
          })
          .then((response) => {
            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;
            console.log("el response", response);
            if (response.data.code == 200) {
              this.$swal({
                title: response.data.message,
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Entendido",
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

              this.$eventBus.emit("reiniciarFormVenta");
              this.$eventBus.emit("closeModalVenta");
              this.$eventBus.emit("reiniciarListadoVentas");
            } else {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              if (response.data.code == 401) {
                localStorage.removeItem("userData");
                this.$store.state.user = "";

                this.$router.push({ name: "auth-login" });
              }
            }
          })
          .catch((error) => {
            console.log("Aquí va el error", error);

            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;

            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          });
      } else {
        this.$toast.error("El número celular es un campo obligatorio", {
          position: "top-right",
          max: 10,
        });
      }
    },

    atras() {
      this.step = 1;
      this.firstStep = true;
      this.textStep = "Ubicación de domicilio";
    },

    onSubmitFirstStep(event) {
      event.preventDefault();

      this.isDisabled = true;

      document.getElementById("btn_solicitar").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Comprobando cobertura';

      this.$https
        .post("/ventas/getCoberturaVenta/", {
          tokenAuth: this.tokenAuth,
          latitude: this.center.lat,
          longitude: this.center.lng,
        })
        .then((response) => {
          document.getElementById("btn_solicitar").innerHTML =
            'Siguiente <i  style="margin-left: 10px;" class="fas fa-arrow-circle-right"></i>';
          this.isDisabled = false;

          if (response.data.code == 200) {
            this.isDisabled = false;
            this.step = 2;
            this.firstStep = false;
            this.textStep = "Información general";
          } else {
            if (response.data.code == 405) {
              this.$swal({
                title: response.data.message,
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Entendido",
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              if (response.data.code == 401) {
                localStorage.removeItem("userData");
                this.$store.state.user = "";

                this.$router.push({ name: "auth-login" });
              }
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Ocurrio un error " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    cargarScript() {
      // As an instance method inside a component
      this.$loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyD_vYcja0tqGvy3RgrDwD2bqLUxcQNc3v0"
      )
        .then(() => {
          // Script is loaded, do something
          this.instanceMap();
        })
        .catch(() => {
          // Failed to fetch script
          this.cargarScript();
        });
    },

    checkDNI() {
      if (this.dni == "") {
        this.displayName = "";
        this.dniRespaldo = "";
      } else if (this.dni != this.dniRespaldo) {
        document.getElementById("btn_submit").innerHTML =
          '<i class="fas fa-spinner fa-pulse"></i>  Comprobando cédula, espere';

        this.isDisabled = true;

        this.$https
          .post("/profile/getConsultaDatos/", {
            tokenAuth: this.tokenAuth,
            cedula: this.dni,
          })
          .then((response) => {
            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;

            if (response.data.code == 200) {
              this.dniRespaldo = this.dni;
            } else {
              this.dni = "";
              this.dniRespaldo = "";

              this.displayName = "";
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });
            }
          })
          .catch((error) => {
            this.dni = "";

            this.displayName = "";

            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          });
      }
    },

    checkPhone() {
      if (this.phoneNumber == "") {
        this.phoneRespaldo = "";
      } else if (this.phoneNumber != this.phoneRespaldo) {
        document.getElementById("btn_submit").innerHTML =
          '<i class="fas fa-spinner fa-pulse"></i>  Comprobando celular, espere';

        this.isDisabled = true;

        this.$https
          .post("/profile/checkUserBan/", {
            tokenAuth: this.tokenAuth,
            dato: this.phoneNumber,
          })
          .then((response) => {
            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;

            if (response.data.code == 200) {
              this.phoneRespaldo = this.phoneNumber;
            } else {
              this.phoneNumber = "";
              this.phoneRespaldo = "";

              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });
            }
          })
          .catch((error) => {
            this.phoneNumber = "";

            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          });
      }
    },

    checkEmail() {
      if (this.email == "") {
        this.emailRespaldo = "";
      } else if (this.email != this.emailRespaldo) {
        document.getElementById("btn_submit").innerHTML =
          '<i class="fas fa-spinner fa-pulse"></i>  Comprobando email, espere';

        this.isDisabled = true;

        this.$https
          .post("/profile/checkUserBan/", {
            tokenAuth: this.tokenAuth,
            dato: this.email,
          })
          .then((response) => {
            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;

            if (response.data.code == 200) {
              this.emailRespaldo = this.email;
            } else {
              this.email = "";
              this.emailRespaldo = "";

              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });
            }
          })
          .catch((error) => {
            this.email = "";

            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          });
      }
    },
  },
  computed: {},
  watch: {
    results(newValue) {
      if (newValue.isValid) {
        this.phoneNumber = newValue.e164;
        this.hasPhone = true;

        this.checkPhone();
      } else {
        this.hasPhone = false;
      }
    },
    displayName() {
      this.displayName = this.displayName.toUpperCase();
    },
  },
  mounted() {
    this.cargarScript();
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped></style>
