
export default function useComp(){


    function logout(userId,tokenAuth, isDashboard) {

 
      this.$swal({
        title: "¿Seguro que deseas cerrar sesión?",
        icon: 'question',
        width: '400px',
        showCancelButton: true,
        confirmButtonText: 'Cerrar sesión',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
        
           const dataEncripted = this.$encryptBackoffice.encrypt(userId);


          return this.$https.post('/acceso/logout/', {tokenAuth: tokenAuth, userId: dataEncripted }).then(response => response.data).catch(error => {
            this.$swal.showValidationMessage(
              error,
            )
          })




        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then(result => {

        this.$swal.close()

        if(result.isConfirmed){


          if (result.value.code == 200) {

          
                    
                    localStorage.removeItem('userData')
this.$store.state.user="";
                    
                    


                    if(isDashboard){
                      
                      this.$router.push({ name: 'Signin' })
                    }else{
                      location.reload();
                    }

 
                    this.$toast.success('Sesión cerrada exitosamente', {
                        position:"top-right",
                        max: 10
                        })


    
                  } else {

                    this.$toast.error(result.value.message, {
                        position:"top-right",
                        max: 10
                        })

            

                    if (result.data.code == 401) {
                      
                      localStorage.removeItem('userData')
this.$store.state.user="";

                      

                      // Redirect to login page
                      this.$router.push({ name: 'Signin' })
                    }
                  }

        }
        



  
      })



    }
    return {

        logout
    }
}