<template>


    <div class="card" style="margin-bottom:10px">

      <div class=" card-body">

        <div class="row">

            <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11"> 

                <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i> Solicitudes de retiros de dinero</p>

            </div>

          
            <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex"> 
                            <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                            <div v-else @click="refresh">
                              <i  style="cursor:pointer; margin:auto" class="fas fa-sync text-sm opacity-10"></i>
                            </div>
                           
                        </div>

        </div>

        <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px"> 

            <div class="row">

                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"> 

                    <Skeletor :shimmer="true" height="40" width="50%" />

                </div>

                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"> 

                    <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                    <Skeletor :shimmer="true" height="40" width="100%" />

                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                <table class="table tablesorter" >
                      <thead class="thead-light">
                        <tr>
                          <th> <Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                          <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                        </tr>
                      </thead>
                      <tbody>
    
    
                        <tr>
    
                            <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                            <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                            <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                            <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                             <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                   
                           
    
                  
    
                        </tr>
                        <tr>
                                    
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>

                                



                                </tr>
                                <tr>
                                    
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                    <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>

                                



                                </tr>
                
                      </tbody>
                    </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8"> 
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"> 

                            <div class="row"> 
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"> 
                                    <Skeletor :shimmer="true" height="10" width="100%" /> 
                                    </div>

                                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center"> 
                                        <Skeletor :shimmer="true" circle size="30" /> 
                                </div>

                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"> 
                                    <Skeletor :shimmer="true" height="10" width="100%" /> 
                                </div>


                            </div>


                        </div>

            </div>

        </div>

        <div v-else-if="notAllow" class="row">
            
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">

                <img style="width:100px;" src="/img/caution.svg" />

                <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.</p>


            </div>

        </div>

        <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
      width="100%"
        >
        <thead>
            <tr>
            <th >Fecha/Hora</th>
            <th data-priority="1">Usuario</th>
            <th>DNI</th>
            <th>Contacto</th>
            <th>Monto real</th>
            <th>Monto a transferirse</th>
            <th data-priority="2">Acción</th>
            </tr>
        </thead>

        

 

        </DataTable>

      
      </div>

    </div>


    <VueFinalModal v-model="showModal" :esc-to-close="true"  class="modal-container" content-class="modal-content">


      <div class="modal__content">

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 

                <p style="text-align: center; font-weight: bold; margin:0px">Datos del usuario</p>
              

                <p class="p_text"><span class="textLabel">Usuario: </span>{{displayName}}</p>



                                
                    <p class="p_text"><span class="textLabel">DNI: </span>{{dni}}</p>




                    <p class="p_text"><span class="textLabel">Contacto: </span>{{contact}}</p>

            </div> 

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                <hr class="horizontal dark" />
                <p style="text-align: center; font-weight: bold; margin:0px">Datos bancarios</p>
              

                <p class="p_text"><span class="textLabel">Banco: </span>{{bank}}</p>


                        
                    <p class="p_text"><span class="textLabel">Nro. cuenta: </span>{{numberaccount}}</p>


                    <p class="p_text"><span class="textLabel">Tipo: </span>{{type}}</p>

                </div> 

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                <hr class="horizontal dark" />
                <p style="text-align: center; font-weight: bold; margin:0px">Datos de transacción</p>
              

                <p class="p_text"><span class="textLabel">Fecha: </span>{{date}}</p>


                                

                <p class="p_text"><span class="textLabel">Referencia: </span>{{ref}}</p>




                <p class="p_text"><span class="textLabel">Monto: </span>$ {{mount}}</p>




                <p class="p_text"><span class="textLabel">Monto a transferir: </span>$ {{mounTransfer}}</p>

                </div> 

        </div>

 

      </div>
    </VueFinalModal>


  
</template>

<script>

/* eslint-disable */

import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
import $ from "jquery";
import {  VueFinalModal } from 'vue-final-modal'
   import 'vue-final-modal/style.css'


DataTable.use(DataTablesCore);

export default {
components: {

    DataTable,
    VueFinalModal


},
props: ['userId', 'tokenAuth', 'idAdmin'],
data() {

return {
    loading: true,
    showModal:false,
    transactions:[],
    notAllow:false,
    displayName:"",dni:"", contact:"", bank:"", numberaccount:"", type:"", date:"", ref:"", mount:"", mounTransfer:"",
    columns : [
        { data: 'dateFormated' },
        { data: 'displayName' },
        { data: 'dni' },
        { data: 'contact' },
        { data: 'mount' },
        { data: 'mount_transferir' },
        { data: null, render: function(data,type, row, meta){
            return (`
            <div class="dropdown">
  <button class="btn btn-primary btn-sm dropdown-toggle " type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="fas fa-ellipsis-v" style="margin-right:10px"></i>
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
    <li><button data-id="${data.idTransaction}" data-displayname="${data.displayName}" data-bank="${data.bank}" data-userid="${data.userId}"  data-mount="${data.mount}" data-tipo="Aprobar" class="aprobar dropdown-item" type="button"><i class="fas fa-check-circle" style="margin-right:10px"></i>Aprobar retiro</button></li>
    <li><button data-id="${data.idTransaction}" data-displayname="${data.displayName}" data-bank="${data.bank}" data-userid="${data.userId}"  data-mount="${data.mount}" data-tipo="Rechazar" class="dropdown-item rechazar" type="button"><i class="fas fa-times-circle" style="margin-right:10px"></i>Rechazar retiro</button></li>
    <li><button  data-mountransfer="${data.mount_transferir}" data-date="${data.dateFormated}" data-mount="${data.mount}" data-ref="${data.ref}" data-type="${data.type}" data-displayname="${data.displayName}" data-bank="${data.bank}" data-numberaccount="${data.numberAccount}" data-contact="${data.contact}" data-dni="${data.dni}" class="dropdown-item verDatos" type="button"><i class="fas fa-eye" style="margin-right:10px"></i>Ver datos</button></li>
  </ul>
</div>


            `)} },
        ], options : {
                            responsive: true,
                            "ordering": false,
                            columnDefs: [
                                                    { responsivePriority: 1, targets: 0 },
                                                    { responsivePriority: 2, targets: -1 }
                                                ],
                            lengthMenu: [
                              [7,15,25, 35, 50, -1],
                              [7,15,25, 35, 50, "All"],
                            ],
                            pageLength: 7, 
                               "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                          },

}
},
computed: {

},
watch: {

},
mounted() {

    $(document).off('click', '.aprobar');
    $(document).off('click', '.rechazar');


    $(document).on("click", ".aprobar", (evt) => {
     
   
     const displayName = $(evt.target).data('displayname');
     const bank= $(evt.target).data('bank');
     const tipo= $(evt.target).data('tipo');
     const idTransaction= $(evt.target).data('id');
     const userId= $(evt.target).data('userid');
     const mount= $(evt.target).data('mount');

     this.aprobar(idTransaction, userId, tipo, displayName, bank, mount); 
 });


 
 $(document).on("click", ".rechazar", (evt) => {
     
   
     const displayName = $(evt.target).data('displayname');
     const bank= $(evt.target).data('bank');
     const tipo= $(evt.target).data('tipo');
     const idTransaction= $(evt.target).data('id');
     const userId= $(evt.target).data('userid');
     const mount= $(evt.target).data('mount');

     this.rechazar(idTransaction, userId, tipo, displayName, bank, mount); 
 });




   $(document).off('click', '.verDatos');
   

   $(document).on("click", ".verDatos", (evt) => {
     
       const displayName = $(evt.target).data('displayname');
       const dni = $(evt.target).data('dni');
       const contact = $(evt.target).data('contact');
       const bank= $(evt.target).data('bank');
       const numberaccount= $(evt.target).data('numberaccount');
       const type= $(evt.target).data('type');
       const date= $(evt.target).data('date');
       const ref= $(evt.target).data('ref');
       const mount= $(evt.target).data('mount');
       const mounTransfer= $(evt.target).data('mountransfer');
       
     this.showDatos(displayName,dni, contact, bank, numberaccount, type, date, ref, mount, mounTransfer);
     
   });

this.getListado();

},
methods: {

    rechazar(idTransaction, userId, tipo, displayName, bank, mount){


        let title="";
          let textButton="";
          let html="";
          html='<div style="margin-top:10px" class="table-responsive"><table class="table table-bordered" style="text-align: center;"><tbody><tr><td class="background_table">Usuario:</td><td class="td_table">'+displayName+'</td></tr><tr><td class="background_table">Banco:</td><td class="td_table">'+bank+'</td></tr><tr> <td class="background_table">Monto:</td><td class="td_table">$'+mount+' USD</td></tr></tbody></table><p style="text-align: left;margin-bottom: 0px;margin-top: 15px;">Ingrese el motivo del rechazo de la solicitud:</p>'
                

        
            title='¿Estas seguro que deseas rechazar esta solicitud?';
             textButton="Rechazar";

            this.$swal({
            title: title,
            html: html,
            icon: 'warning',
            showCancelButton: true,
            input: 'text',
            inputPlaceholder: "Motivo del rechazo de solicitud",
                inputAttributes: {
                    autocapitalize: 'off'
                },
            confirmButtonText: textButton,
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: (motivo) => {

                if (motivo != "") {
                    const data_json = { userId: this.userId,motivo:motivo, userIdUser:userId,idAdmin:this.idAdmin,  tipo:tipo, idTransaction:idTransaction  }
                        const data_string = JSON.stringify(data_json)

                    

                        const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

                        return this.$https.post('/administracion/withdrawcheck/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                            this.$swal.showValidationMessage(
                            error,
                            )
                        })


                    } else {
                    this.$swal.showValidationMessage('El motivo del rechazo es obligatorio')   
                    }

         
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then(result => {

            this.$swal.close()

            if(result.isConfirmed){

          
              if (result.value.code == 200) {

                this.refresh();
                   
                this.$toast.success(result.value.message, {
                                    position:"top-right",
                                    max: 10
                                    })


        
                      } else {
                        this.$toast.error(result.value.message, {
                                    position:"top-right",
                                    max: 10
                                    })
                        if (result.data.code == 401) {

                            
                            localStorage.removeItem('userData')
                                            this.$store.state.user="";


                                                            // Redirect to login page
                                                            this.$router.push({ name: 'login' })

                          
           

                        }else{
                              if (result.data.code == 402) {
                                this.refresh();

                              }
                            }
                      }

            }
            



      
          })

    },

    aprobar(idTransaction, userId, tipo, displayName, bank, mount){

        

        let title="";
          let textButton="";
          let html="";
          html='<div style="margin-top:10px" class="table-responsive"><table class="table table-bordered" style="text-align: center;"><tbody><tr><td class="background_table">Usuario:</td><td class="td_table">'+displayName+'</td></tr><tr><td class="background_table">Banco:</td><td class="td_table">'+bank+'</td></tr><tr> <td class="background_table">Monto:</td><td class="td_table">$'+mount+' USD</td></tr></tbody></table>'
                
            title='¿Estas seguro que deseas aprobar esta solicitud?';
             textButton="Aprobar";
      

            this.$swal({
            title: title,
            html: html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: textButton,
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              const data_json = { userId: this.userId, userIdUser:userId,idAdmin:this.idAdmin,  tipo:tipo, idTransaction:idTransaction  }
              const data_string = JSON.stringify(data_json)

           

              const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

              return this.$https.post('/administracion/withdrawcheck/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                this.$swal.showValidationMessage(
                  error,
                )
              })
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then(result => {

            this.$swal.close()

            if(result.isConfirmed){


              if (result.value.code == 200) {

                this.refresh();
                   
                this.$toast.success(result.value.message, {
                                    position:"top-right",
                                    max: 10
                                    })


        
                      } else {
                        this.$toast.error(result.value.message, {
                                    position:"top-right",
                                    max: 10
                                    })
                        if (result.data.code == 401) {

                            
                            localStorage.removeItem('userData')
                                            this.$store.state.user="";


                                                            // Redirect to login page
                                                            this.$router.push({ name: 'login' })

                          
           

                        }else{
                              if (result.data.code == 402) {
                                this.refresh();

                              }
                            }
                      }

            }
            



      
          })


    },

    showDatos(displayName,dni, contact, bank, numberaccount, type, date, ref, mount, mounTransfer){

        this.showModal = true;

        this.displayName=displayName;
        this.dni=dni;
         this.contact=contact;
         this.bank=bank;
         this.numberaccount=numberaccount;
         this.type=type;
         this.date=date;
         this.ref=ref;
         this.mount=mount;
         this.mounTransfer=mounTransfer;

    },

   
refresh() {
    $(document).off('click', '.verDatos');
    $(document).off('click', '.aprobar');
    $(document).off('click', '.rechazar');
   
        this.$eventBus.emit('reiniciarListadoRetirosAdmin')
      },

      getListado(){


        const data_json = { userId: this.userId, idAdmin: this.idAdmin }
        const data_string = JSON.stringify(data_json)

 

        const dataEncripted = this.$encryptBackoffice.encrypt(data_string);
            this.$https.post('/administracion/listWithDraw/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

            if (response.data.code == 200) {
            this.loading = false;
            this.notAllow=false;



            this.transactions= response.data.transactions;



           
            } else {




            if (response.data.code == 401) {

           
            
                    localStorage.removeItem('userData')
                    this.$store.state.user="";


                 // Redirect to login page
                 this.$router.push({ name: 'login' })
            } else {

                if (response.data.code == 403) {

                this.$router.push({ name: 'Dashboard' })

                }else{

                    if (response.data.code == 404) {

                        this.loading = false;
                        this.notAllow=false;

                    }else{

                        if(response.data.code === 501){
                            this.loading=false;

                            this.notAllow=true;

                            }else{
                                this.getListado();
                            }
                   
                    }
                   
                }
                   
            }
            }
            }).catch(error => {
                      this.getListado();
            })
            },





},
}
</script>

<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';


</style>
  