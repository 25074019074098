
<template>
 
    <div>

        <VueFinalModal v-model="showModal" :esc-to-close="true" @closed="closed" class="modal-container" content-class="modal-content">

          
      <div class="modal__content">

     


            <div class="row"> 

                <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 " @click="closed">  

            
                    <i class="fas fa-times-square close_modal"></i>

                    </div>

                        <div class="col-7 col-sm-7 col-md-7 col-xl-7 col-lg-7">  

                        <p style=" font-weight: bold; font-size:14px; margin:0px; text-align: left;">Editar imagen</p>





                        </div>

                        <div class="col-4 col-sm-4 col-md-4 col-xl-4 col-lg-4">  

                            <button id="btn_foto" @click="crop()" :disabled="isActiveFoto" data-bs-toggle="tooltip" data-bs-placement="right" title="Guardar" class="btn btn-secondary btn-sm" style="width: 100%;border-radius: 50px; padding: 5px 0px;">
                                      Guardar
                                </button>



                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12" style="margin-top:15px">  

   

                            <cropper
                                ref="cropper"
                                :auto-zoom="true"
                                :stencil-component="$options.components.CircleStencil"
                                :src="image.src"
                                :stencil-size="{
                                    width: 500,
                                    height: 500
                                }"
                            />


                            </div>

     

  

                </div>

          



      

      </div>
    </VueFinalModal>


    </div>
   </template>
   
   <script>
   /*eslint-disable */
   import 'firebase/compat/storage'

   import { CircleStencil,Cropper } from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';
    import {  VueFinalModal } from 'vue-final-modal'
   import 'vue-final-modal/style.css'

   export default {
       name: "ModalPhoto",
       components: {
        Cropper,CircleStencil,VueFinalModal
    },
       props: ['image','userId','tokenAuth'],
       data() {

  
            
            return {


                showModal:false,
                isActiveFoto:false,
 

            }
       },
       activated() {
   
           
           
       },
       methods: {


        crop() {
			const { canvas } = this.$refs.cropper.getResult();
			canvas.toBlob((blob) => {
				// Do something with blob: upload to a server, download and etc.

                console.log(blob);

                this.isActiveFoto = true

                const d1 = new Date();

                let nameFile= this.userId+"_"+d1.getTime();
                let dir= 'profilePhoto/'+this.userId;
                
                  const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(blob);

               
                storageRef.on('state_changed', snapshot => {
                 

                    document.getElementById('btn_foto').innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> Subiendo '
                }, error => {


                 
                    this.$toast.error("Ocurrió un error inesperado: "+ error, {
                        position: "top-right",
                        max: 10
                        })
      

                    this.isActiveFoto = false
                    document.getElementById('btn_foto').innerHTML = "Guardar"
                },
                () => {
              
                    storageRef.snapshot.ref.getDownloadURL().then(url => {

                       // const myArray = url.split("?alt=media");




                      //  let urlFinal=myArray[0];



                    const data_json = { userId: this.userId, url }
                 

                    document.getElementById('btn_foto').innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> Guardando'

                    this.$https.post('/profile/updatePhotoProfile/', { tokenAuth: this.tokenAuth, data: data_json }).then(response => {
                        
                       
                        if (response.data.code == 200) {

                            const datos_json = JSON.parse(this.$store.state.user);
    

 
                            datos_json.photoUser = url
                            localStorage.setItem('userData', JSON.stringify(datos_json))

                            this.$store.state.user= JSON.stringify(datos_json);
                      
                  


                  

                            this.$eventBus.emit('updateFotoPerfil', url)
                  

                        document.getElementById('btn_foto').innerHTML = "Guardar"
                    
                        this.$toast.success(response.data.message, {
                        position: "top-right",
                        max: 10
                        })

                        this.closed()
                        } else {

                 
                            this.$toast.error(response.data.messag, {
                        position: "top-right",
                        max: 10
                        })
                       
                        if (response.data.code == 401) {
                                            
                                localStorage.removeItem('userData')
                                    this.$store.state.user="";


                                // Redirect to login page
                                this.$router.push({ name: 'login' })
                        }
                        }
                    }).catch(error => {

                        this.$toast.error(error, {
                        position: "top-right",
                        max: 10
                        })
             
                    })
                    })
                })
                

			}, this.image.type);
		},

        closed(){
          
            this.$eventBus.emit('reiniciarModalPhoto');
        },

   
       },
       mounted() {


        this.isActiveFoto = false

        this.showModal=true;
   
   
   }
   }    
   </script>
   
   
   <style scoped>

   
   </style>