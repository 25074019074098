/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import perfil from "../views/Perfil.vue";
import editarPerfil from "../views/Dashboard/EditarPerfil.vue";
import NotFound from "../views/404.vue";
import Venta from "../views/Venta.vue";
import Invitacion from "../views/Invitacion.vue";
import Landing from "../views/Landing.vue";
import DatosBancarios from "../views/DatosBancarios.vue";
import Terminos from "../views/Terminos.vue";
import Descripcion from "../views/Descripcion.vue";
import store from "@/store/index";
import Referidos from "../views/Referidos/Index.vue";
import Mas from "../views/Mas.vue";
import Password from "../views/Password.vue";
import transacciones from "../views/Dashboard/Transacciones.vue";
import detalleVentas from "../views/Dashboard/DetalleVentas.vue";
import Administracion from "../views/Administracion/Index.vue";
import recursos from "../views/Dashboard/Recursos.vue";

const routes = [
  {
    path: "/",
    name: "landing",
    component: Signin,
    meta: { layout: "full" },
  },
  {
    path: "/invitacion/:promoCode?",
    name: "Invitacion",
    component: Invitacion,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/referidos",
    name: "referidos",
    component: Referidos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mi equipo XTRIMPRO",
    },
  },
  {
    path: "/dashboard/banco",
    name: "banco",
    component: DatosBancarios,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Cuenta bancaria",
    },
  },
  {
    path: "/dashboard/mas",
    name: "mas",
    component: Mas,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Otras opciones",
    },
  },
  {
    path: "/dashboard/terminos",
    name: "terminos",
    component: Terminos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Términos y condiciones",
    },
  },
  {
    path: "/dashboard/descripcion",
    name: "descripcion",
    component: Descripcion,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Descripción y Términos de XTRIMPRO",
    },
  },
  {
    path: "/venta/:promoCode?",
    name: "Venta",
    component: Venta,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, layout: "horizontal", breadCrumb: "Inicio" },
  },
  {
    path: "/administracion",
    name: "Administracion",
    component: Administracion,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Administración",
    },
  },
  {
    path: "/login",
    name: "Signin",
    component: Signin,
    meta: { layout: "full" },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/editarPerfil",
    name: "editarPerfil",
    component: perfil,
    meta: { requiresAuth: true, layout: "full" },
  },
  {
    path: "/dashboard/perfil",
    name: "Perfil",
    component: editarPerfil,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Editar perfil",
    },
  },
  {
    path: "/dashboard/password",
    name: "password",
    component: Password,
    meta: { requiresAuth: true, layout: "full" },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/transacciones",
    name: "transacciones",
    component: transacciones,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mis transacciones",
    },
  },
  {
    path: "/dashboard/ventas",
    name: "ventas",
    component: detalleVentas,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mis referidos",
    },
  },
  {
    path: "/dashboard/recursos",
    name: "recursos",
    component: recursos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Recursos",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, _, next) => {
  var condition = navigator.onLine ? "online" : "offline";

  if (condition === "online") {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const user = store.state.user;

      if (user) {
        try {
          const datos_json = JSON.parse(user);

          let token = datos_json.tokenAuth;

          if (typeof token === "undefined" || token === "") {
            localStorage.removeItem("userData");
            store.state.user = "";

            return next({ name: "Signin" });
          } else {
            let t = datos_json.t;

            if (typeof t === "undefined" || t === "") {
              localStorage.removeItem("userData");
              store.state.user = "";

              return next({ name: "Signin" });
            } else {
              const is_incorrect = isNaN(t);

              if (is_incorrect) {
                localStorage.removeItem("userData");
                store.state.user = "";

                return next({ name: "Signin" });
              }

              const currentDate = new Date().getTime();
              if (Number(currentDate) > Number(t)) {
                localStorage.removeItem("userData");
                store.state.user = "";

                return next({ name: "Signin" });
              } else {
                let dni = datos_json.dni;

                if (typeof dni === "undefined" || dni === "") {
                  if (to.path != "/dashboard/editarPerfil") {
                    return next({ name: "editarPerfil" });
                  } else {
                    return next();
                  }
                } else {
                  let hasPassword = datos_json.hasPassword;

                  if (to.path === "/dashboard/editarPerfil") {
                    if (
                      typeof hasPassword === "undefined" ||
                      hasPassword === "" ||
                      hasPassword === false
                    ) {
                      if (to.path != "/dashboard/password") {
                        return next({ name: "password" });
                      } else {
                        return next();
                      }
                    } else {
                      return next({ name: "Dashboard" });
                    }
                  } else {
                    if (
                      typeof hasPassword === "undefined" ||
                      hasPassword === "" ||
                      hasPassword === false
                    ) {
                      if (to.path != "/dashboard/password") {
                        return next({ name: "password" });
                      } else {
                        return next();
                      }
                    } else {
                      if (to.path === "/dashboard/password") {
                        return next({ name: "Dashboard" });
                      } else {
                        return next();
                      }
                    }
                  }
                }
              }
            }
          }
        } catch (error3) {
          // eliminar storage
          // redirect login

          localStorage.removeItem("userData");
          store.state.user = "";

          return next({ name: "Signin" });
        }
      } else {
        return next({ name: "Signin" });
      }
    } else {
      if (to.path == "/login") {
        const user = store.state.user;

        if (user) {
          const datos_json = JSON.parse(user);

          let token = datos_json.tokenAuth;

          if (typeof token === "undefined" || token === "") {
            localStorage.removeItem("userData");
            store.state.user = "";

            return next();
          } else {
            let t = datos_json.t;

            if (typeof t === "undefined" || t === "") {
              localStorage.removeItem("userData");
              store.state.user = "";

              return next();
            } else {
              const is_incorrect = isNaN(t);

              if (is_incorrect) {
                localStorage.removeItem("userData");
                store.state.user = "";

                return next();
              }

              const currentDate = new Date().getTime();
              if (Number(currentDate) > Number(t)) {
                localStorage.removeItem("userData");
                store.state.user = "";

                return next();
              }

              return next({ name: "Dashboard" });
            }
          }
        } else {
          return next();
        }
      } else {
        const user = store.state.user;

        if (user) {
          const datos_json = JSON.parse(user);

          let token = datos_json.tokenAuth;

          if (typeof token === "undefined" || token === "") {
            localStorage.removeItem("userData");
            store.state.user = "";

            return next();
          } else {
            let t = datos_json.t;

            if (typeof t === "undefined" || t === "") {
              localStorage.removeItem("userData");
              store.state.user = "";

              return next();
            } else {
              const is_incorrect = isNaN(t);

              if (is_incorrect) {
                localStorage.removeItem("userData");
                store.state.user = "";

                return next();
              }

              const currentDate = new Date().getTime();
              if (Number(currentDate) > Number(t)) {
                localStorage.removeItem("userData");
                store.state.user = "";

                return next();
              }

              return next();
            }
          }
        } else {
          return next();
        }
      }
    }
  } else {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/conexion.html";
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
