<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" align="center">
      <Skeletor
        v-if="loading"
        height="40"
        width="50%"
        style="margin-bottom: 0px; margin-top: 15px"
      />
      <h6
        v-else
        style="
          font-weight: bold;
          margin-bottom: 20px;
          line-height: 15px;
          margin-top: 20px;
          font-size: 25px;
          color: #67748e;
        "
        class="text-center"
      >
        $<span style="font-size: 40px"> {{ cashDisponible }}</span>
      </h6>
      <h6
        style="
          font-size: 11px;
          margin-bottom: 20px;
          line-height: 15px;
          color: #67748e;
        "
        class="text-center"
      >
        Saldo disponible para retirar
      </h6>
      <hr class="horizontal dark" />
      <form role="form" autocomplete="off" @submit.prevent="sendCash">
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center"
          >
            <h6
              style="
                font-weight: bold;
                margin-bottom: 20px;
                line-height: 15px;
                font-size: 14px;
                color: black;
              "
              class="text-center"
            >
              ¿Cuanto vas a retirar?
            </h6>
          </div>
          <div
            class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center"
          >
            <cleave
              v-model="retiroCash"
              :options="number"
              class="form-control autonumeric_input"
              placeholder="$0.00"
              required
              type="text"
              inputmode="decimal"
              :readonly="isDisable"
              @blur="onBlurMount"
            />
          </div>

          <div
            class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center"
          >
            <button
              class="btn btn-primary btn-sm"
              style="width: 100%"
              :disabled="isDisable"
              type="submit"
            >
              Retirar dinero
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Saldo from "./Saldo.vue";

export default {
  components: {
    Saldo,
  },
  props: ["userId", "tokenAuth"],
  data() {
    return {
      isDisable: true,
      loading: true,
      retiroCash: "",
      cashDisponible: "0",
      number: {
        prefix: "$",
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralIntegerScale: 9,
        numeralDecimalScale: 2,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$eventBus.on("reiniciarSaldos", () => {
      this.componentKeySaldos += 1;
    });

    this.getSaldo();
  },
  methods: {
    onBlurMount() {
      console.log(this.retiroCash);

      if (
        this.retiroCash == null ||
        this.retiroCash == "null" ||
        this.retiroCash == ""
      ) {
        this.retiroCash = "";
      } else {
        if (Number(this.retiroCash) === Number("0")) {
          this.retiroCash = "";

          this.$toast.error("El monto del retiro no puede ser igual a cero", {
            position: "top-right",
            max: 10,
          });
        }
      }
    },

    getSaldo() {
      const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId);

      this.$https
        .post("/profile/getBalance/", {
          tokenAuth: this.tokenAuth,
          userId: userIdEncripted,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.loading = false;
            (this.isDisable = false),
              (this.cashDisponible = response.data.balance);
          } else {
            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            } else {
              this.getSaldo();
            }
          }
        })
        .catch((error) => {
          this.getSaldo();
        });
    },
    sendCash(event) {
      event.preventDefault();

      if (this.retiroCash === "") {
        this.$toast.error("El monto de retiro no puede estar vacío", {
          position: "top-right",
          max: 10,
        });
        this.retiroCash = "";
      } else {
        if (Number(this.retiroCash) > Number(this.cashDisponible)) {
          this.$toast.error(
            "Lo sentimos, el monto que desea retirar supera a su saldo disponible",
            {
              position: "top-right",
              max: 10,
            }
          );
          this.retiroCash = "";
        } else {
          this.$swal({
            title:
              "¿Seguro que deseas hacer un retiro por $" +
              this.retiroCash +
              "?",
            icon: "warning",
            width: "400px",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-secondary ml-1",
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.isDisable = true;

              const userId_json = {
                userId: this.userId,
                retiroCash: this.retiroCash,
              };
              const user_string = JSON.stringify(userId_json);

              const dataEncripted =
                this.$encryptBackoffice.encrypt(user_string);

              return this.$https
                .post("/banks/retirarCash/", {
                  tokenAuth: this.tokenAuth,
                  datos: dataEncripted,
                })
                .then((response) => response.data)
                .catch((error) => {
                  this.$swal.showValidationMessage(error);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then((result) => {
            this.$swal.close();

            if (result.isConfirmed) {
              this.isDisable = false;

              if (result.value.code == 200) {
                this.$toast.success(result.value.message, {
                  position: "top-right",
                  max: 10,
                });

                this.$eventBus.emit("closeModalRetiro");
                this.$eventBus.emit("reiniciarSaldos");
              } else {
                if (result.value.code == 401) {
                  localStorage.removeItem("userData");
                  this.$store.state.user = "";

                  // Redirect to login page
                  this.$router.push({ name: "login" });
                } else {
                  this.$toast.error(result.value.message, {
                    position: "top-right",
                    max: 10,
                  });

                  if (result.value.code == 600) {
                    this.retiroCash = "";
                  }
                }
              }
            }
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
